import ButtonCloseMenu from 'components/button-close'
import useMainClass from 'hooks/use-main-cass'
import textServices from 'services/text-services'

const Rules = ({ closeRules }: { closeRules: () => void }) => {
  useMainClass('referrals__rules')

  return (
    <article className="w-full flex flex-col items-center pt-4 gap-6 pb-8 px-4">
      <ButtonCloseMenu paramFn={closeRules} />
      <h1 className="referrals__rules__title">
        {textServices.findByText('referrals/rules/title', {
          es: 'Reglas de participación',
          en: 'Participation Rules'
        })}
      </h1>
      <div className="flex flex-col gap-6 referrals__rules__list">
        {textServices.findByText('referrals/rules/list', <></>)}
      </div>
    </article>
  )
}

export default Rules
