import { GET_EVENTS } from 'constants/api'
import useAxios from 'axios-hooks'
import { createContext, Dispatch, FunctionComponent, useContext, useEffect, useReducer } from 'react'
import { useSession } from 'contexts/session-context'
import eventsReducer, { EventsAction, EventsState, IEvent, IEventPlaces } from './reducer'

export interface EventsContextProps {
  state: EventsState
  loading: boolean
  dispatch: Dispatch<EventsAction>
}

const EventsContext = createContext<EventsContextProps>({} as EventsContextProps)

export const EventsProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(eventsReducer, {})
  const [{ data: session }] = useSession()
  const [{ data, loading }] = useAxios<IEvent>(
    {
      url: GET_EVENTS,
      headers: { Authorization: `Bearer ${((session as any)?._tokenResponse as any)?.idToken}`, 'Ph-Api-Id': PH_API_ID ?? 1 }
    },
    { useCache: false }
  )

  useEffect(() => {
    if (data) {
      dispatch({ type: 'SET_EVENT', payload: data })
    }
  }, [data])

  return <EventsContext.Provider value={{ state, loading, dispatch }}>{children}</EventsContext.Provider>
}

export const useEvents = () => {
  const { state, loading, dispatch } = useContext(EventsContext)

  const setPlaces = (places: IEventPlaces[]) => dispatch({ payload: places, type: 'SET_EVENT_PLACES' })

  const actions = { setPlaces }

  const returnValue: [typeof state, typeof actions, typeof loading] = [state, actions, loading]
  return returnValue
}