import CONFIGS from 'build/Config'
import { useMemo, useState } from 'react'
import Button from 'components/button'
import { BackBtn } from 'views/login/text.style'
import textServices from 'services/text-services'
import useMainClass from 'hooks/use-main-cass'
import ButtonCloseMenu from 'components/button-close'
import LoadingScreen from 'components/loading-screen'
import ErrorView from 'components/error-view'
import ExchangeComponent from 'components/exchange'
import { useSession } from 'contexts/session-context'
import { Wheel } from './modules/components/Wheel'
import { useWheel } from './hooks/useWheel'
import { WheelData } from './modules/components/Wheel/types'

const Chances = ({ chances }: { chances: number }) => {
  if (chances)
    return (
      <div className="chances border rounded h-10 py-1 px-4 gap-4 flex items-center">
        <p className="text big flex-1">Chances de girar la rueda</p>
        <div className="h-full chances-divisor" />
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9 0C10.3125 0 11.5703 0.273438 12.7734 0.820312C13.9141 1.36719 14.9062 2.11719 15.75 3.07031V0.75H17.25V6H12V4.5H14.9531C14.2656 3.57812 13.4062 2.85156 12.375 2.32031C11.3125 1.77344 10.1875 1.5 9 1.5C7.95312 1.5 6.95312 1.70312 6 2.10938C5.09375 2.51562 4.29688 3.07812 3.60938 3.79688C2.92188 4.51562 2.39844 5.34375 2.03906 6.28125L0.65625 5.71875C1.09375 4.59375 1.72656 3.60156 2.55469 2.74219C3.38281 1.88281 4.33594 1.21875 5.41406 0.75C6.55469 0.25 7.75 0 9 0ZM15.9609 11.7188L17.3438 12.2812C16.9062 13.4062 16.2734 14.3984 15.4453 15.2578C14.6172 16.1172 13.6641 16.7812 12.5859 17.25C11.4453 17.75 10.25 18 9 18C7.67188 18 6.41406 17.7188 5.22656 17.1562C4.08594 16.625 3.09375 15.8828 2.25 14.9297V17.25H0.75V12H6V13.5H3.02344C3.72656 14.4219 4.58594 15.1484 5.60156 15.6797C6.66406 16.2266 7.79688 16.5 9 16.5C10.0469 16.5 11.0469 16.2969 12 15.8906C12.9062 15.4844 13.7031 14.9219 14.3906 14.2031C15.0781 13.4844 15.6016 12.6562 15.9609 11.7188Z"
            fill="currentColor"
          />
          <circle cx="9" cy="9" r="2.5" stroke="currentColor" />
        </svg>
        <p className="font-bold description medium">{chances}</p>
      </div>
    )

  return (
    <div className="chances border rounded h-10 py-1 px-4 flex items-center justify-center">
      <p className="text big">¡No tienes ninguna chance acumulada!</p>
    </div>
  )
}

const Rules = ({ closeRules }: { closeRules: () => void }) => {
  useMainClass('wheel-fortune__rules')

  return (
    <article className="w-full flex flex-col items-center pt-4 gap-6 pb-8 px-4">
      <ButtonCloseMenu paramFn={closeRules} />
      <h1 className="title medium">Reglas de participación</h1>
      <div className="flex flex-col gap-6">{textServices.findByText('wheelFortune/rules', <></>)}</div>
    </article>
  )
}

export default function WheelFortune() {
  useMainClass('wheel-fortune')
  const [mustSpin, setMustSpin] = useState(false)
  const [prizeNumber, setPrizeNumber] = useState(0)
  const [openRules, setOpenRules] = useState(false)
  const [congrats, setCongrats] = useState(false)
  const [{ wheel, error, prize }, { playWheel, setError }] = useWheel()
  const [{ user }] = useSession()

  const handleSpinClick = async () => {
    if (!mustSpin && wheel?.status && wheel?.profileWheelFortuneChances?.chances > 0) {
      const position = await playWheel()
      if (position === 0 || position) {
        setPrizeNumber(position)
        setMustSpin(true)
      }
    }
  }

  const handleOnStopSpinning = () => {
    setMustSpin(false)
    setTimeout(() => {
      setCongrats(true)
    }, 500)
  }

  const data = useMemo(() => {
    return wheel?.prizes?.map((item) => {
      const obj: WheelData = {
        option: item?.name,
        image: {
          uri: item?.imageUrl,
          offsetY: 160
        }
      }

      return obj
    })
  }, [wheel])

  const handleButtonsCongrats = () => {
    setCongrats(null)
  }

  if (!wheel) return <LoadingScreen />

  if (error) return <ErrorView code={error} buttonMenu={() => setError(0)} onRetry={() => setError(0)} />

  if (congrats)
    return (
      <ExchangeComponent
        buttons={{
          handleMenu: handleButtonsCongrats,
          text: 'VOLVER A LA RULETA',
          handleButtonCommon: handleButtonsCongrats,
          handleButtonSticker: handleButtonsCongrats
        }}
        item={prize}
        title={prize?.title === 'MALA SUERTE' ? `¡LASTIMA ${user?.firstName}!` : null}
        description={prize?.title === 'MALA SUERTE' ? `MEJOR SUERTE LA PRÓXIMA` : null}
        module="wheel-fortune"
      />
    )

  if (openRules) {
    return <Rules closeRules={() => setOpenRules(false)} />
  }

  return (
    <article className="w-full flex flex-col items-center pt-4 gap-6 pb-8 px-4">
      <h1 className="title big">RUEDA DE LA FORTUNA</h1>
      <section className="flex-1 flex flex-col gap-4">
        <div className="relative">
          <img src={`${AZURE_BASE_URL}/wheel-logo.webp`} className="wheel-logo" />
          <Wheel
            mustStartSpinning={mustSpin}
            prizeNumber={prizeNumber}
            data={data}
            spinDuration={1.0}
            startingOptionIndex={0}
            onStopSpinning={handleOnStopSpinning}
            {...(CONFIGS.wheel ?? {})}
          />
        </div>
        <Chances chances={wheel?.profileWheelFortuneChances?.chances} />
        <p className="text big font-medium text-center">
          {wheel?.profileWheelFortuneChances?.chances ? (
            <>
              <b>Gira la rueda y espera a ver que suerte te toca.</b>
              <br />
              <br />
              Completa alguna de las activiadades de la pataforma para desbloquear nuevas chances de girar la rueda y
              ganar premios.
            </>
          ) : (
            <>
              Completa alguna de las activiadades de la pataforma para desbloquear nuevas chances de girar la rueda y
              ganar premios.
            </>
          )}
        </p>
      </section>
      <div className="w-full flex flex-col items-center">
        <Button onClick={handleSpinClick} style={{ opacity: wheel?.profileWheelFortuneChances?.chances ? 1 : 0.5 }}>
          GIRAR LA RUEDA
        </Button>
        <button type="button" className="cursor-pointer button-back" onClick={() => setOpenRules(true)}>
          <BackBtn className={`din mt-3 text-center hover:text-red-100 uppercase underline`} style={{ fontSize: 10 }}>
            VER LAS REGLAS
          </BackBtn>
        </button>
      </div>
    </article>
  )
}
