import { AUTH, SOCIAL_CALLBACK } from 'constants/api'
import { useSession } from 'contexts/session-context'
import { signInWithCustomToken, getAuth, UserCredential } from 'firebase/auth'
import { useContext, useEffect, useState } from 'react'
import LoadingScreen from 'components/loading-screen'
import useRouter from 'hooks/use-router'
import utmServices from 'services/utm-services'
import axios, { AxiosError } from 'axios'
import MetaCAPIService from 'services/meta-capi.services'
import { createUserProperties, dlSetUserInfo, dlTrackEvent } from 'components/dataLayer/dataLayer'
import { LoaderContext } from 'contexts/loader-context'
import { UserData } from 'contexts/session-context/session-reducer'
import ProfileService from 'services/profile-services'
import useRedirect from 'hooks/use-redirect'
import AllowCookies from 'components/allow-cookies'
import ErrorView from 'components/error-view'
import { getBirthdayLocal } from 'utilities/birthday'
import { Prize } from 'views/prizes/types'
import ExchangeComponent from 'components/exchange'
import CONFIGS from 'build/Config'
import textServices from 'services/text-services'

const Callback = () => {
  const { push, location } = useRouter()
  const [session, { setSession, setUser }] = useSession()
  const firbaseAuth = getAuth()
  const [, { redirectByLocal, getRedirectLocal }] = useRedirect()
  const { setLoad } = useContext(LoaderContext)
  const [allowCookies, setAllowCookies] = useState<{ callback: any; isAllow: boolean }>()
  const [loginError, setLoginError] = useState<number | undefined>()
  const [birthday] = useState(getBirthdayLocal())
  const [prize, setPrize] = useState<{ prize: Prize; name: string; buttonText; callback: () => void }>(null)

  const completeData = (user: UserData, session: UserCredential) => {
    setSession(session)
    setUser(user)
    const utms = localStorage.getItem('utmAuthSocial')

    const external = localStorage.getItem('toexternal')
    if (external) {
      localStorage.removeItem('toexternal')
      return window.location.assign(decodeURI(JSON.parse(external)))
    }

    if (getRedirectLocal()) return redirectByLocal()

    const availableFields = CONFIGS?.notAvailableFields
    const isComplete =
      (availableFields?.includes('nickName') ? false : !user?.nickName) ||
      !user?.birthday ||
      !user?.genre ||
      !user?.genreId
    if (!CONFIGS?.signUp?.base && isComplete) return push('/information/update?isNew=true' + `&${utms}`)

    return push(`/home?${utms}`)
  }

  const authCallback = async (data?: {
    termsOfUse?: boolean
    privacyPolicy?: boolean
    subscribe?: boolean
    code?: string
    session: UserCredential
    user: UserData
    prize?: Prize
    loginType: string | null
  }) => {
    setLoad(true)
    const { session, user, termsOfUse, privacyPolicy, subscribe, code, prize, loginType } = data || {}
    try {
      dlSetUserInfo(user?.hashId ?? user?.id)
      createUserProperties(user, loginType)
      let payload = { termsOfUse, privacyPolicy, subscribe, code }
      const consumption = localStorage.getItem('consumption')

      if (consumption) {
        payload = { ...payload, consumptionCenterId: JSON.parse(consumption) ?? 0 } as any
      }

      let userData = user
      if (termsOfUse || privacyPolicy || subscribe || code) {
        const response = await ProfileService.socialLogin({
          payload: payload,
          credentials: session
        })
        userData = response?.data
      }

      if (prize)
        return setPrize({
          prize,
          name: userData?.firstName,
          buttonText: '',
          callback: () => completeData(userData, session)
        })

      return completeData(userData, session)
    } catch (error) {
      setAllowCookies({ callback: authCallback, isAllow: false })
      const code = (error as AxiosError).response?.data.code || 99999
      setLoginError(code)
    } finally {
      setLoad(false)
    }
  }

  const auth = async () => {
    const params = location.hash.replace('#', '')
    const parsedParams = new URLSearchParams(params)
    const state = parsedParams.get('state') || ''
    const isStateOk = decodeURIComponent(localStorage.getItem('stateAuthSocial') || '') === state

    if (isStateOk) {
      localStorage.removeItem('stateAuthSocial')
      const token = parsedParams.get('access_token') || ''
      const stateProvider = state.split('&')
      if (token && state && stateProvider?.length === 1) {
        try {
          const { data } = await axios.get(`${SOCIAL_CALLBACK}${stateProvider[0]}/signin?code=${token}`)
          const firebaseLogin = await signInWithCustomToken(firbaseAuth, data.firebaseAccessToken)
          const names = firebaseLogin.user?.displayName?.split(' ') || ['', '']
          const firstName = names[0]
          const lastName = names[names.length - 1]
          const accessToken = (firebaseLogin.user as any).accessToken
          const utm = utmServices.utmRead()
          const lang = textServices.language

          const body: any = { accessToken: data?.providerAccessToken, firstName, lastName, utm, lang }

          const bithdayUser = (firebaseLogin as any)?.user?.reloadUserInfo?.dateOfBirth
            ? new Date((firebaseLogin as any)?.user?.reloadUserInfo?.dateOfBirth)
            : birthday

          if (bithdayUser && !Number.isNaN(bithdayUser)) {
            body.birthday = bithdayUser
          }

          const loginResponse = await axios.post(AUTH, body, {
            headers: { Authorization: `Bearer ${accessToken}`, 'Ph-Api-Id': PH_API_ID ?? 1 }
          })
          if (!loginResponse.data?.profile?.termsOfUse || !loginResponse.data?.profile?.privacyPolicy) {
            gtag('event', 'sign_up', {
              event_label: `SignUp - ${state}`,
              method: `${state}`
            })
            MetaCAPIService.emitCompleteRegistration(
              firebaseLogin.user.email,
              firstName,
              lastName,
              '',
              window.location.href,
              'Meta',
              MetaCAPIService.uuidv4()
            )
            return setAllowCookies({
              callback: (data: { termsOfUse: boolean; subscribe: boolean; code?: string }) =>
                authCallback({
                  ...data,
                  session: firebaseLogin,
                  user: loginResponse.data?.profile,
                  prize: loginResponse?.data?.prize,
                  loginType: stateProvider[0]
                }),
              isAllow: true
            })
          } else {
            gtag('event', 'login', {
              event_label: `Login - ${state}`,
              method: `${state}`
            })
            dlTrackEvent('login', {
              method: `${state}`
            })
          }

          authCallback({
            session: firebaseLogin,
            user: loginResponse.data?.profile,
            prize: loginResponse?.data?.prize,
            loginType: stateProvider[0]
          })
        } catch (error) {
          const code = (error as AxiosError).response?.data.code || 99999
          setLoginError(code)
        }
      } else if (token && state && session && stateProvider?.length > 1) {
        try {
          const linkAccountResponse = await axios.put(
            `${SOCIAL_CALLBACK}${stateProvider[0]}/link?code=${token}`,
            null,
            {
              headers: {
                Authorization: `Bearer ${((session as any)?.data?._tokenResponse as any)?.idToken}`,
                'Ph-Api-Id': PH_API_ID ?? 1
              }
            }
          )

          if (linkAccountResponse) {
            const prize = linkAccountResponse?.data as Prize
            if (prize)
              return setPrize({
                prize,
                name: session.user?.firstName,
                buttonText: textServices.findByText(`profile/social/exchange/description`, {
                  es: 'Vincular otras cuentas',
                  en: 'Link another account'
                }),
                callback: () => push('/profile/social')
              })

            push('/profile/social')
          }
        } catch (error) {
          const code = (error as AxiosError).response?.data.code || 99999
          setLoginError(code)
        }
      }
    } else {
      push('/')
    }
  }

  useEffect(() => {
    auth()
  }, [])

  if (prize) {
    return (
      <ExchangeComponent
        buttons={{
          handleMenu: prize?.callback,
          handleButtonCommon: prize?.callback,
          handleButtonSticker: prize?.callback,
          text: prize?.buttonText
        }}
        item={prize?.prize}
        module="login"
        name={prize?.name}
      />
    )
  }

  if (allowCookies?.isAllow) {
    return <AllowCookies onSubmit={allowCookies?.callback} />
  }

  if (loginError) {
    return <ErrorView code={loginError as number} buttonMenu={() => push('/')} onRetry={() => push('/')} />
  }

  return <LoadingScreen />
}

export default Callback
