import { AxiosError } from "axios";
import { Dispatch } from "react";

export enum SurveyTypeEnum {
  REGISTER = 'Register',
  LIBRE = 'Libre',
  TRIVIAS = 'Trivias',
  TRIVIAQR = 'TriviaQR',
  REDEEM = 'Redeem',
  PRIZES = 'Prizes',  
  VOTE = 'Vote',
  RALLY = 'Rally',
  COLLECTIONS = 'Collections',
  SORTEO = 'Sorteo',
  SECRETSANTA = 'SecretSanta',
  MEMORAMA = 'Memorama'
}

export interface Prize {
  title: string
  points: number
  description: any
  productId: number
  imageUrl: string
  stickerId: number
}

export interface Option {
  optionId: number
  option: string
  imageUrl?: string,
  demandSpaceId: number,
  description: string
}

export interface Question {
  questionId: number;
  question: string;
  subtitle: string;
  type: 'Normal' | 'Dropdown' | 'Checkbox' | 'TextBox' | 'RadioButton';
  options: Option[];
  amountAwnsers: number;
}

export interface Survey {
  id: number;
  encuestaType: 'Polaris' | 'Normal'
  activationType: SurveyTypeEnum,
  name: string;
  isAnswered: boolean;
  redirectUrl: string;
  questions: Question[];
  optional: boolean;
  profilePercentIncluded: boolean;
  viewType: 'SinglePage' | 'Steps'
  buttonText: string
  prize?: Prize
}

export interface SurveyState {
  surveys?: Survey[];
  survey?: Survey;
  error?: AxiosError,
  prize?: Prize,
  demandSpace?: DemandSpace
  surveyInfo?: SurveyInfo
}

export interface SurveyContextProps {
  state: SurveyState
  dispatch: Dispatch<SurveyAction>
}

export enum SurveyActionEnum {
  SET_SURVEYS = 'SET_SURVEYS',
  SET_SURVEY = 'SET_SURVEY',
  SET_PRIZE = 'SET_PRIZE',
  SET_DEMAND_SPACE = 'SET_DEMAND_SPACE',
  RESET = 'RESET',
  SET_ERROR = 'SET_ERROR',
  SET_INFO_SURVEY = 'SET_INFO_SURVEY'
}

export type SurveyActionType = SurveyActionEnum.SET_SURVEY | SurveyActionEnum.SET_ERROR | SurveyActionEnum.SET_SURVEYS | SurveyActionEnum.SET_PRIZE | SurveyActionEnum.SET_DEMAND_SPACE | SurveyActionEnum.RESET | SurveyActionEnum.SET_INFO_SURVEY

export interface SurveyAction {
  type: SurveyActionType
  payload: SurveyState | Survey | Survey[] | number | Prize | DemandSpace | AxiosError | SurveyInfo
}

export interface SurveyOptions {
  autoRedirect?: boolean;
  redirect?: string;
}

export interface DemandSpace {
  id: number,
  name: string,
  imageUrl: string
  videoUrl: string
}

export interface SurveyInfo {
  buttonText: string
  resultUrl: string
}

export interface SurveyResponse {
  questionId: number,
  textValue: string,
  optionIds: number[]
}

