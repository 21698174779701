import textServices from 'services/text-services'
import { Link } from 'react-router-dom'
import { dlTrackEvent } from 'components/dataLayer/dataLayer'

const Header = () => {
  const navigateToId = () => {
    dlTrackEvent('user_interaction', {
      action: 'click',
      element: 'go to id',
      section: 'referrals'
    })
  }

  const handleBackArrow = () => {
    history.back()
  }

  return (
    <div className="w-full referrals__header">
      <div className="referrals__header__title">
        <button onClick={handleBackArrow}>
          <img src={`${AZURE_BASE_URL}/referrals-arrow.webp`} alt="Arrow" className="referrals__header__title__arrow" />
        </button>
        <h2>
          {textServices.findByText('referrals/title', {
            es: 'Referir código',
            en: 'Refer code'
          })}
        </h2>
      </div>

      <Link to="/profile/identificacion" className="referrals__header__download-btn" onClick={navigateToId}>
        <img src={`${AZURE_BASE_URL}/referrals-card.webp`} alt="Card" />
        <p>
          {textServices.findByText('referrals/download-id', {
            es: 'Descargar ID',
            en: 'Download ID'
          })}
        </p>
      </Link>
    </div>
  )
}

export default Header
