import textServices from 'services/text-services'
import { dlTrackEvent } from 'components/dataLayer/dataLayer'
import { LoaderContext } from 'contexts/loader-context'
import { useSession } from 'contexts/session-context'
import { useContext, useEffect } from 'react'
import useRouter from 'hooks/use-router'
import { SurveyTypeEnum } from 'contexts/survey-context/survey.interface'
import { useSurvey } from 'contexts/survey-context/useSurvey'
import { useRouteMatch } from 'react-router-dom'
import { AxiosError } from 'axios'
import LoadingScreen from 'components/loading-screen'
import useMainClass from 'hooks/use-main-cass'
import useRedirect from 'hooks/use-redirect'
import CONFIGS from 'build/Config'
import ExchangeComponent from 'components/exchange'
import SurveyPolaris from './components/surveyPolaris'
import PopUpError from './components/popUpError'
import DemandSpaceComponent from './components/demandSpace'
import PostalCode from './components/postalCode'
import SurveyStepper from './components/surveyStepper'

interface IProps {
  isDirectAccess?: boolean
}

function Survey({ isDirectAccess = false }: IProps) {
  const { setLoad } = useContext(LoaderContext)
  const [{ data }] = useSession()
  const { push } = useRouter()
  const route = useRouteMatch<{ id: string }>()
  const { query }: { query: { redirect?: string; actionType?: SurveyTypeEnum; id?: number } } = useRouter()
  const [{ url }] = useRedirect()
  const [{ state, surveyLoading }, { updatePolarisSurvey, validateSurvey, resetSurvey }] = useSurvey()
  useMainClass('survey')
  const redirect = () => {
    resetSurvey()
    return push(
      state?.survey?.redirectUrl === '' || !state?.survey?.redirectUrl
        ? query?.redirect ?? url ?? '/home'
        : state?.survey?.redirectUrl
    )
  }

  const handleSubmit = async (callback: () => Promise<void>) => {
    setLoad(true)
    try {
      await callback()
    } catch (error) {
      const err = error as AxiosError
      resetSurvey()
      return push(`/error/${err?.response?.data?.code ?? 99999}`)
    } finally {
      setLoad(false)
    }
  }

  useEffect(() => {
    const fetchSurvey = async () => {
      await validateSurvey(route.params.id, isDirectAccess)
    }
    if (data) {
      fetchSurvey()
    }
  }, [data])

  useEffect(() => {
    setLoad(surveyLoading)
  }, [surveyLoading])

  if (state?.demandSpace)
    return <DemandSpaceComponent demandSpace={state?.demandSpace} redirect={redirect} surveyInfo={state?.surveyInfo} />

  if (state?.prize) {
    return (
      <ExchangeComponent
        buttons={{
          handleMenu: () => redirect(),
          handleButtonCommon: () => {
            gtag('event', 'event_to_ga', {
              event_category: 'Survey',
              event_label: textServices.findByText('trivias/exchange/button/text', 'VER MIS PREMIOS'),
              value: state?.survey?.id
            })
            dlTrackEvent('user_interaction', {
              action: 'click',
              element: textServices.findByText('trivias/exchange/button/text', 'VER MIS PREMIOS'),
              section: `survey :: ${state?.survey?.id}`
            })
            redirect()
          },
          handleButtonSticker: () => {
            gtag('event', 'event_to_ga', {
              event_category: 'Survey',
              event_label: textServices.findByText('trivias/exchange/button/text', 'VER MIS SELLOS'),
              value: state?.survey?.id
            })
            dlTrackEvent('user_interaction', {
              action: 'click',
              element: textServices.findByText('trivias/exchange/button/text', 'VER MIS PREMIOS'),
              section: `survey :: ${state?.survey?.id}`
            })
            redirect()
          },
          dontShowButtons: CONFIGS?.trivia?.dontShowButtonExchange,
          text: state?.survey?.buttonText
        }}
        item={state?.prize}
        module="trivias"
      />
    )
  }

  if (state?.error) {
    // if is not direct access, redirect to default page to avoid show error page
    if (!isDirectAccess) return <>{redirect()}</>
    return <PopUpError errorCode={state?.error} />
  }

  if (surveyLoading) return <LoadingScreen />

  if (state?.survey?.encuestaType === 'Normal' && state.survey?.isAnswered && (state?.survey?.viewType !== 'Steps' || THEME !== 'indio')) return <>{redirect()}</>

  if (THEME === 'indio' && state?.survey?.viewType === 'Steps') {

    return (
      <PostalCode
        redirect={redirect}
        title={state?.survey?.name}
        id={state?.survey?.id}
        questions={state?.survey?.questions}
        handleSubmit={handleSubmit}
        updatePolarisSurvey={updatePolarisSurvey}
      />
    )
  }

  if (state?.survey?.viewType === 'Steps') return (
    <SurveyStepper
      survey={state?.survey}
      updatePolarisSurvey={updatePolarisSurvey}
      resetSurvey={resetSurvey}
      redirect={redirect}
    />
  )

  return (
    <SurveyPolaris
      title={state?.survey?.name}
      prize={state?.survey?.prize}
      id={state?.survey?.id}
      questions={state?.survey?.questions}
      handleSubmit={handleSubmit}
      updatePolarisSurvey={updatePolarisSurvey}
      redirect={redirect}
      isOptional={state?.survey?.optional}
    />
  )
}

export default Survey
