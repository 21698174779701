import textServices from 'services/text-services'
import { Data } from '../referrals.interface'
const ProgressBar = ({ data }: { data: Data }) => {
  const referrals = data?.profileCluster?.referredAmount ?? 0
  const maxUsers = data?.team.maxUser > 0 ? data?.team.maxUser : 10

  const calculateRange = (): { min: number; max: number } => {
    const max = maxUsers
    const min = 0
    return { min, max }
  }

  const calculateProgress = (referrals: number, range: { min: number; max: number }): number => {
    const { max } = range
    if (referrals > max) {
      return 100
    }
    const progress = (referrals / max) * 100
    return Math.min(100, progress)
  }

  const calculateDisplayRange = (referrals: number, range: { min: number; max: number }): string => {
    const { max } = range
    return `${referrals >= 1 && referrals <= 9 ? '0' : ''}${referrals}/${max}`
  }

  const range = calculateRange()
  const progress = calculateProgress(referrals, range)
  const displayRange = calculateDisplayRange(referrals, range)

  return (
    <div className="referrals__progress-bar">
      <div className="referrals__progress-bar__info">
        <div className="referrals__progress-bar__info__referrals">
          <p>
            {displayRange}{' '}
            {textServices.findByText('referrals/progress-bar/name', {
              es: 'referidos',
              en: 'referrals'
            })}
          </p>
        </div>
        <div className="referrals__progress-bar__info__points">
          <p>
            {data?.profileCluster?.referralPoints ? '+' : ''}
            {data?.profileCluster?.referralPoints ?? '00'} pts
          </p>
        </div>
      </div>
      <div className="referrals__progress-bar__track">
        <div className="referrals__progress-bar__track__fill" style={{ width: `${progress}%` }} />
      </div>
    </div>
  )
}

export default ProgressBar
