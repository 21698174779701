import { AxiosError } from 'axios'
import ErrorView from 'components/error-view'
import FormConsumption from 'components/form-consumption'
import { LoaderContext } from 'contexts/loader-context'
import { useSession } from 'contexts/session-context'
import useQuery from 'hooks/use-query'
import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ProfileService from 'services/profile-services'

function ConsumptionsUpdate() {
  const params = useQuery()
  const history = useHistory()
  const [{ data: session }, { setUser }] = useSession()
  const { setLoad } = useContext(LoaderContext)
  const [error, setError] = useState(null)
  const [showAlert, setShowAlert] = useState(params.get('alert') === 'true')
  const [showExcellent, setShowExcellent] = useState(false)

  const handleSubmit = async (data: any) => {
    setLoad(true)
    try {
      const payload = { session, consumptionId: data?.consumption }
      const response = await ProfileService.updateConsumption(payload)
      setUser(response.data)
      if (params.get('alert') === 'true') {
        setShowExcellent(true)
      } else {
        history.goBack()
      }
    } catch (err) {
      const typedError = err as AxiosError
      const { code } = typedError.response?.data || {}
      setError(code)
    } finally {
      setLoad(false)
    }
  }

  const handleMenu = () => {
    setError(null)
  }

  const handleButton = () => {
    setError(null)
  }

  if (error) return <ErrorView buttonMenu={handleMenu} code={error} onRetry={handleButton} />

  if (showExcellent) return <ErrorView code={9856} buttonMenu={() => history.push('/home')} onRetry={() => history.push('/home')} />

  if (showAlert) return <ErrorView code={9855} buttonMenu={() => setShowAlert(false)} onRetry={() => setShowAlert(false)} />

  return (
    <section className='flex w-full pt-7'>
      <FormConsumption onFormSubmit={handleSubmit} />
    </section>
  )
}

export default ConsumptionsUpdate