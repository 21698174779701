import { GET_REFERRED_ACTIVE, GET_REFERRED_TEAM, PUT_CREATE_CLUSTER_TEAM } from 'constants/api'
import axios from 'axios'
import { LoaderContext } from 'contexts/loader-context'
import { useSession } from 'contexts/session-context'
import { useContext, useEffect, useState } from 'react'

export const useReferral = () => {
  const [error, setError] = useState<number | string>()
  const { setLoad } = useContext(LoaderContext)
  const [dynamic, setDynamic] = useState<any>(null)
  const [team, setTeam] = useState<any>(null)
  const [{ data: session }] = useSession()

  const getReferredActive = async () => {
    setLoad(true)
    try {
      const response = await axios.get(GET_REFERRED_ACTIVE, {
        headers: {
          Authorization: `Bearer ${(session as any)._tokenResponse?.idToken}`,
          'Ph-Api-Id': PH_API_ID ?? 1
        }
      })

      setDynamic(response?.data)
    } catch (error) {
      setError(error?.response?.data?.code ?? 99999)
    } finally {
      setLoad(false)
    }
  }

  const getReferredTeam = async () => {
    console.log('getReferredTeam',dynamic?.id);
    
    setLoad(true)
    try {
      const response = await axios.get(GET_REFERRED_TEAM.replace(':teamId', dynamic?.id), {
        headers: {
          Authorization: `Bearer ${(session as any)._tokenResponse?.idToken}`,
          'Ph-Api-Id': PH_API_ID ?? 1
        }
      })
      setTeam(response?.data)
    } catch (error) {
      setError(error?.response?.data?.code ?? 99999)
    } finally {
      setLoad(false)
    }
  }

  const createClusterTeam = async () => {
    setLoad(true)
    try {
      await axios.put(PUT_CREATE_CLUSTER_TEAM.replace(':teamId', dynamic?.id), null, {
        headers: {
          Authorization: `Bearer ${(session as any)._tokenResponse?.idToken}`,
          'Ph-Api-Id': PH_API_ID ?? 1
        }
      })
    } catch (error) {
      setError(error?.response?.data?.code ?? 99999)
    } finally {
      setLoad(false)
    }
  }

  useEffect(() => {
    getReferredActive()
  }, [])

  return [
    { dynamic, error, team },
    { getReferredTeam, createClusterTeam, setError }
  ]
}
