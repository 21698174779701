import { SubtitleSurveyLabel, SubtitleLabelSurvey } from "theme/survey";
import { SelectGender } from "theme/theme";
import { Option } from "contexts/survey-context/survey.interface";
import { dlTrackEvent } from "components/dataLayer/dataLayer";

export interface DropDownProps {
  questionId: number;
  title: string;
  subtitle: string;
  items?: Option[];
  onChange: (key: number, selected: string[]) => void;
}

const DropDown = (props: DropDownProps) => {

  const onChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    const itemId = event.currentTarget.value as string;

    dlTrackEvent('user_interaction', {
      action: 'select',
      element: `${props?.title} :: ${itemId}`,
      section: `survey`
    })
    gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'SURVEY - Seleccionar encuesta', value: '10' })

    if (itemId === 'SELECCIONA UNA RESPUESTA') return props.onChange(props?.questionId, []);

    return props.onChange(props?.questionId, [itemId]);
  };

  return (
    <>
      <div className="p-2 w-full label-survey">
        <SubtitleSurveyLabel className="font-black text-2xl text-center md:text-3xl subtitle-option">
          {props.title}
        </SubtitleSurveyLabel>
        <SubtitleLabelSurvey className="text-lg md:text-xl subtitle-option">
          {props.subtitle}
        </SubtitleLabelSurvey>
      </div>
      <div className={`justify-center my-2 gap-2 md:mx-auto w-full text-black`}>
        <SelectGender className="w-full h-16 px-4 dinPro text-sm font-medium" onChange={onChange}>
          <option value={null}>SELECCIONA UNA RESPUESTA</option>
          {props.items &&
            props.items.map(({ optionId, option }) => {
              return (
                <option
                  key={optionId}
                  className="aspect-square flex items-end relative"
                  id={String(optionId)}
                  value={String(optionId)}
                  data-kind={optionId}
                >
                  {option}
                </option>
              );
            })}
        </SelectGender>
      </div>
    </>
  );
};

export default DropDown;
