import { useRef, useState } from 'react'
import Button from 'components/button'
import { useSession } from 'contexts/session-context'
import textServices from 'services/text-services'
import { dlTrackEvent } from 'components/dataLayer/dataLayer'
import { QRCodeCanvas } from 'qrcode.react'
import { Data } from '../referrals.interface'

const Code = ({ data }: { data: Data }) => {
  const contentRef = useRef(null)

  const [dynamic, setDynamic] = useState(false)

  const [{ user }] = useSession()

  const urlUser = `${URL_BASE}${process.env.PUBLIC_URL}/signup?referralCode=${user?.referralCode}`

  const handleCopy = () => {
    dlTrackEvent('user_interaction', {
      action: 'click',
      element: 'copy referral code',
      section: 'referrals'
    })

    navigator.clipboard.writeText(urlUser).then(
      () => console.log('text copied'),
      () => console.log('error copying text')
    )
  }

  const handleShare = () => {
    dlTrackEvent('user_interaction', {
      action: 'click',
      element: 'share referral code',
      section: 'referrals'
    })

    window.navigator.share({
      title: textServices.findByText('refferal/title', 'Código de referido'),
      text: `${textServices.findByText('refferal/text1', `¡Hola! ¡Usa mi código `)} ${user?.referralCode} ${textServices.findByText('refferal/text2', ` para registrarte!`)}`,
      url: urlUser
    })
  }

  const toggleDynamic = () => {
    dlTrackEvent('user_interaction', {
      action: 'click',
      element: !dynamic ? 'see dynamic' : 'close dynamic',
      section: 'referrals'
    })

    setDynamic(!dynamic)
  }

  return (
    <div className="referrals__code transition-transform">
      <p className="referrals__code__title">
        {textServices.findByText('referrals/code/title', {
          es: 'Comparte tu código para obtener puntos extra por cada nuevo referido y participar por premios especiales.',
          en: 'Share your code to earn extra points for each new referral and participate in special prize giveaways.'
        })}
      </p>

      <QRCodeCanvas value={urlUser} size={200} className="referrals__code__qr mx-auto" />

      <div className="referrals__code__share">
        <p className="referrals__code__share__text">
          {textServices.findByText('referrals/code/share', {
            es: 'Comparte tu código',
            en: 'Share your code'
          })}
        </p>

        <div className="referrals__code__hex-digit">
          <p>{user?.referralCode}</p>
        </div>

        <div className="flex gap-3 items-center justify-center w-full referrals__code__button">
          <Button className="referrals__code__button__copy" onClick={handleCopy}>
            {textServices.findByText('referrals/code/button/copy', {
              es: 'COPIAR',
              en: 'COPY'
            })}
          </Button>
          <Button className="referrals__code__button__share" onClick={handleShare}>
            {textServices.findByText('referrals/code/button/share', {
              es: 'COMPARTIR',
              en: 'SHARE'
            })}
          </Button>
        </div>

        <button className="referrals__code__dynamic" onClick={toggleDynamic}>
          <p className="referrals__code__dynamic__title">
            {textServices.findByText('referrals/code/dynamic/title', {
              es: 'Sobre la dinámica',
              en: 'About the dynamic'
            })}
          </p>
          <img
            src={`${AZURE_BASE_URL}/referrals-dynamic-icon.webp`}
            className={`referrals__code__dynamic__icon transform ${dynamic ? 'rotate-180' : 'rotate-0'} transition-transform`}
            loading="lazy"
            alt="Icon"
          />
        </button>

        <div
          ref={contentRef}
          className={`referrals__code__dynamic__content ${dynamic ? 'referrals__code__dynamic__content__active' : ''}`}
          style={{
            maxHeight: dynamic ? `${contentRef.current?.scrollHeight}px` : '0px'
          }}
        >
          {textServices.findByText('referrals/code/dynamic/content', {
            es: (
              <p className="referrals__code__dynamic__content__empty">
                Todavía no hay información sobre la dinámica. Vuelve más tarde.
              </p>
            ),
            en: (
              <p className="referrals__code__dynamic__content__empty">
                There is no information about the dynamic yet. Please check back later.
              </p>
            )
          })}
          {textServices.findByText('referrals/code/dynamic/prize', {
            es: (
              <h2 className="referrals__code__dynamic__content__prize__title">Premio:</h2>
            ),
            en: (
              <h2 className="referrals__code__dynamic__content__prize__title">Prize:</h2>
            )
          })}          
          <img src={data?.team?.prizeImageUrl ?? `${AZURE_BASE_URL}/referrals-dynamic-prize.webp`} alt="referral prize" className="referrals__code__dynamic__content__prize__img"></img>
        </div>
      </div>
    </div>
  )
}

export default Code
