const idConfigTheme: Record<string, (canvas: HTMLCanvasElement, value: number | string) => void> = {
  tecate: (canvas, value) => {
    const context = canvas.getContext('2d')
    const font = new FontFace(
      'HeadingProUltracomp',
      "url('https://promohubstorage.blob.core.windows.net/fonts/headingProUltracomp/HeadingProUltracomp-Bold.woff2')"
    )

    font.load().then((font) => {
      if (font) {
        document.fonts.add(font)
        context.font = 'normal 96px HeadingProUltracomp'
        context.fillStyle = 'black'
        context.textAlign = 'center'
        context.textBaseline = 'middle'
        const textX = canvas.width / 2
        const textY = canvas.height - 26 * 4
        context.fillText(`${value}`, textX, textY)
      }
    })
  },

  amstel: (canvas, value) => {
    const context = canvas.getContext('2d')
    const font = new FontFace(
      'DINPro',
      "url('https://cdn-phcentral.azureedge.net/tecate/fonts/DINPro/DINPro-Regular.woff2')"
    )

    font.load().then((font) => {
      if (font) {
        document.fonts.add(font)
        context.font = 'normal 96px DINPro'
        context.fillStyle = 'white'
        context.textAlign = 'center'
        context.textBaseline = 'middle'
        const textX = canvas.width / 2
        const textY = canvas.height - 26 * 4
        context.fillText(`${value}`, textX, textY)
      }
    })
  },

  bohemia: (canvas, value) => {
    const context = canvas.getContext('2d')
    const font = new FontFace(
      'Oswald',
      "url('https://promohubstorage.blob.core.windows.net/bohemia/assets/Oswald-Regular.ttf')"
    )

    font.load().then((font) => {
      if (font) {
        document.fonts.add(font)
        context.font = 'normal 96px Oswald'
        context.fillStyle = 'white'
        context.textAlign = 'center'
        context.textBaseline = 'middle'
        const textX = canvas.width / 2
        const textY = canvas.height - 26 * 4
        context.fillText(`${value}`, textX, textY)
      }
    })
  },

  cartablanca: (canvas, value) => {
    const context = canvas.getContext('2d')
    const font = new FontFace(
      'Jost',
      "url('https://promohubstorage.blob.core.windows.net/cartablanca/assets/fonts/Jost/Jost-SemiBold.ttf')"
    )

    font.load().then((font) => {
      if (font) {
        document.fonts.add(font)
        context.font = '600 96px Jost'
        context.fillStyle = '#15141A'
        context.textAlign = 'center'
        context.textBaseline = 'middle'
        const textX = canvas.width / 2
        const textY = canvas.height - 26 * 4
        context.fillText(`${value}`, textX, textY)
      }
    })
  },

  cristal: (canvas, value) => {
    const context = canvas.getContext('2d')
    const font = new FontFace(
      'Montserrat',
      "url('https://promohubstorage.blob.core.windows.net/cristal/assets/fonts/Montserrat-SemiBold.woff2')"
    )

    font.load().then((font) => {
      if (font) {
        document.fonts.add(font)
        context.font = '600 96px Montserrat'
        context.fillStyle = 'white'
        context.textAlign = 'center'
        context.textBaseline = 'middle'
        const textX = canvas.width / 2
        const textY = canvas.height - 26 * 4
        context.fillText(`${value}`, textX, textY)
      }
    })
  },

  dosequis: (canvas, value) => {
    const context = canvas.getContext('2d')
    const font = new FontFace(
      'Industry-Demi',
      "url('https://promohubstorage.blob.core.windows.net/mixx/assets/fonts/Industry-Demi.woff')"
    )

    font.load().then((font) => {
      if (font) {
        document.fonts.add(font)
        context.font = '400 96px Industry-Demi'
        context.fillStyle = 'white'
        context.textAlign = 'center'
        context.textBaseline = 'middle'
        const textX = canvas.width / 2
        const textY = canvas.height - 26 * 4
        context.fillText(`${value}`, textX, textY)
      }
    })
  },

  heinekenWaiter: (canvas, value) => {
    const context = canvas.getContext('2d')
    const font = new FontFace(
      'Frank',
      "url('https://promohubstorage.blob.core.windows.net/heineken-waiter/assets/fonts/Frank-Regular.woff2')"
    )

    font.load().then((font) => {
      if (font) {
        document.fonts.add(font)
        context.font = '400 96px Frank'
        context.fillStyle = 'white'
        context.textAlign = 'center'
        context.textBaseline = 'middle'
        const textX = canvas.width / 2
        const textY = canvas.height - 26 * 4
        context.fillText(`${value}`, textX, textY)
      }
    })
  },

  indio: (canvas, value) => {
    const context = canvas.getContext('2d')
    const font = new FontFace(
      'BebasNeue',
      "url('https://stblobphcentral.blob.core.windows.net/indio/assets/fonts/BebasNeue-Regular.woff2')"
    )

    font.load().then((font) => {
      if (font) {
        document.fonts.add(font)
        context.font = '400 96px BebasNeue'
        context.fillStyle = '#235345'
        context.textAlign = 'center'
        context.textBaseline = 'middle'
        const textX = canvas.width / 2
        const textY = canvas.height - 26 * 4
        context.fillText(`${value}`, textX, textY)
      }
    })
  },

  silver: (canvas, value) => {
    const context = canvas.getContext('2d')
    const font = new FontFace(
      'BebasNeue',
      "url('https://stblobphcentral.blob.core.windows.net/indio/assets/fonts/BebasNeue-Regular.woff2')"
    )

    font.load().then((font) => {
      if (font) {
        document.fonts.add(font)
        context.font = '400 96px BebasNeue'
        context.fillStyle = '#008038'
        context.textAlign = 'center'
        context.textBaseline = 'middle'
        const textX = canvas.width / 2
        const textY = canvas.height - 26 * 4
        context.fillText(`${value}`, textX, textY)
      }
    })
  },

  heineken00: (canvas, value) => {
    const context = canvas.getContext('2d')
    const font = new FontFace('PT Sans', "url('https://cdn-phcentral.azureedge.net/fonts/PTSans-Regular.ttf')")

    font.load().then((font) => {
      if (font) {
        document.fonts.add(font)
        context.font = '400 96px PT Sans'
        context.fillStyle = 'white'
        context.textAlign = 'center'
        context.textBaseline = 'middle'
        const textX = canvas.width / 2
        const textY = canvas.height - 26 * 4
        context.fillText(`#${value}`, textX, textY)
      }
    })
  },

  silverMemorama: (canvas, value) => {
    const context = canvas.getContext('2d')
    const font = new FontFace(
      'BebasNeue',
      "url('https://stblobphcentral.blob.core.windows.net/indio/assets/fonts/BebasNeue-Regular.woff2')"
    )

    font.load().then((font) => {
      if (font) {
        document.fonts.add(font)
        context.font = '400 96px BebasNeue'
        context.fillStyle = '#008038'
        context.textAlign = 'center'
        context.textBaseline = 'middle'
        const textX = canvas.width / 2
        const textY = canvas.height - 26 * 4
        context.fillText(`${value}`, textX, textY)
      }
    })
  },

  silverOnTrade: (canvas, value) => {
    const context = canvas.getContext('2d')
    const font = new FontFace(
      'BebasNeue',
      "url('https://stblobphcentral.blob.core.windows.net/indio/assets/fonts/BebasNeue-Regular.woff2')"
    )

    font.load().then((font) => {
      if (font) {
        document.fonts.add(font)
        context.font = '400 96px BebasNeue'
        context.fillStyle = '#008038'
        context.textAlign = 'center'
        context.textBaseline = 'middle'
        const textX = canvas.width / 2
        const textY = canvas.height - 26 * 4
        context.fillText(`${value}`, textX, textY)
      }
    })
  },

  silverWaiter: (canvas, value) => {
    const context = canvas.getContext('2d')
    const font = new FontFace(
      'BebasNeue',
      "url('https://stblobphcentral.blob.core.windows.net/indio/assets/fonts/BebasNeue-Regular.woff2')"
    )

    font.load().then((font) => {
      if (font) {
        document.fonts.add(font)
        context.font = '400 96px BebasNeue'
        context.fillStyle = '#008038'
        context.textAlign = 'center'
        context.textBaseline = 'middle'
        const textX = canvas.width / 2
        const textY = canvas.height - 26 * 4
        context.fillText(`${value}`, textX, textY)
      }
    })
  },

  miller: (canvas, value) => {
    const context = canvas.getContext('2d')
    context.font = '700 96px Roboto'
    context.fillStyle = '#B98B39'
    context.textAlign = 'center'
    context.textBaseline = 'middle'
    const textX = canvas.width / 2
    const textY = canvas.height - 26 * 4
    context.fillText(`${value}`, textX, textY)
  },

  mixx: (canvas, value) => {
    const context = canvas.getContext('2d')
    const font = new FontFace(
      'Industry-Demi',
      "url('https://promohubstorage.blob.core.windows.net/mixx/assets/fonts/Industry-Demi.woff')"
    )

    font.load().then((font) => {
      if (font) {
        document.fonts.add(font)
        context.font = '400 96px Industry-Demi'
        context.fillStyle = 'white'
        context.textAlign = 'center'
        context.textBaseline = 'middle'
        const textX = canvas.width / 2
        const textY = canvas.height - 26 * 4
        context.fillText(`${value}`, textX, textY)
      }
    })
  },

  solMezclas: (canvas, value) => {
    const context = canvas.getContext('2d')
    const font = new FontFace(
      'Proxima Nova Condensed',
      "url('https://promohubstorage.blob.core.windows.net/fonts/proximaNova/ProximaNova-CondensedBold.otf')",
      { weight: '800' }
    )

    font.load().then((font) => {
      if (font) {
        document.fonts.add(font)
        context.font = '800 100px Proxima Nova Condensed'
        context.fillStyle = 'white'
        context.textAlign = 'center'
        context.textBaseline = 'middle'
        const textX = canvas.width / 2
        const textY = canvas.height - 30 * 4
        context.fillText(`#${value}`, textX, textY)
      }
    })
  }
}

export default idConfigTheme
