import { prettyError } from 'constants/errors'
import { useContext, useState } from 'react'
import { getAuth, createUserWithEmailAndPassword, AuthError, signInWithEmailAndPassword } from 'firebase/auth'
import FormInformation from 'components/form-information'
import ProfileService from 'services/profile-services'
import utmServices from 'services/utm-services'
import { AxiosError } from 'axios'
import ErrorView from 'components/error-view'
import { getBirthdayLocal } from 'utilities/birthday'
import useRouter from 'hooks/use-router'
import { useSession } from 'contexts/session-context'
import { dlSetUserInfo, dlTrackError, dlTrackEvent, createUserProperties } from 'components/dataLayer/dataLayer'
import useRedirect from 'hooks/use-redirect'
import useMainClass from 'hooks/use-main-cass'
import FormBaseInformation from 'components/form-base-information'
import dayjs from 'dayjs'
import { UserData } from 'contexts/session-context/session-reducer'
import { Prize } from 'views/prizes/types'
import ExchangeComponent from 'components/exchange'
import MetaCAPIService from 'services/meta-capi.services'
import textServices from 'services/text-services'
import CONFIGS from 'build/Config'
import { LoaderContext } from '../../contexts/loader-context'
import Welcome from './components/welcome'
import Done from './components/done'

const auth = getAuth()

const SignUp = () => {
  const { setLoad } = useContext(LoaderContext)
  const [, { setSession, setUser }] = useSession()
  const [{ url }, { redirect }] = useRedirect()
  const [otp, setOtp] = useState(false)
  const [done, setDone] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)
  const [credentials, setCredentials] = useState(null)
  const [birthday] = useState(getBirthdayLocal())
  const { push } = useRouter()
  const [dataUser, setDataUser] = useState<any>(null)
  const [prize, setPrize] = useState<Prize>(null)
  const [prizeToShow, setPrizeToShow] = useState<Prize>(null)
  const [scanQr, setScanQr] = useState(false)

  const handleScanQr = () => {
    setScanQr(!scanQr)
  }

  useMainClass('sign-up')

  const handleUser = (user: UserData, credentials: any) => {
    dlTrackEvent('login', { method: 'email' })
    gtag('event', 'login', { method: 'Email' })
    dlSetUserInfo(user?.hashId ?? user?.id)
    createUserProperties(user, 'email')

    setSession(credentials)
    setUser(user)

    const external = localStorage.getItem('toexternal')
    if (external) {
      localStorage.removeItem('toexternal')
      return window.location.assign(decodeURI(JSON.parse(external)))
    }

    if (url) return redirect()

    const availableFields = CONFIGS?.notAvailableFields
    const isComplete =
      (availableFields?.includes('nickName') ? false : !user?.nickName) ||
      !user?.birthday ||
      !user?.genre ||
      !user?.genreId
    if (!CONFIGS?.signUp?.base && isComplete) return push('/information/update')

    return push('/home')
  }

  const handleLogin = async () => {
    setLoad(true)
    try {
      dlTrackEvent('user_interaction', {
        action: 'click',
        element: 'ingresar',
        section: 'sign up'
      })
      gtag('event', 'Click', {
        event_category: GTM_EVENT_CATEGORY,
        event_label: 'LOGIN - Iniciar sesión',
        value: 'Email'
      })
      const credentials = await signInWithEmailAndPassword(auth, email, password)
      const utm = utmServices.utmRead()
      const {
        data: { profile, prize }
      } = await ProfileService.signInWithEmailAndPassword({ credentials, utm })

      if (prizeToShow) {
        setDataUser(profile)
        return setPrize(prizeToShow)
      }

      if (prize) {
        setDataUser(profile)
        return setPrize(prize)
      }

      handleUser(profile, credentials)
    } catch (error) {
      if (error?.code) {
        return setError(error.code)
      } else if ((error as AxiosError).isAxiosError) {
        const errorCode = (error as AxiosError).response?.data.code
        const errorMessage = (error as AxiosError).response?.data.message
        dlTrackError({
          error_type: 'login',
          error_code: { errorCode },
          error_message: { errorMessage },
          section: 'sign up'
        })
        return setError(errorCode)
      }
    } finally {
      setLoad(false)
    }
  }

  const handleConfirm = async (data) => {
    setLoad(true)
    try {
      const response = await ProfileService.otpVerifyEmail({ code: data.otp, credentials })
      if (response?.status === 200) {
        gtag('event', 'email_verificado', {
          event_label: 'Registro - Email',
          method: 'Email'
        })
        dlTrackEvent('sign_up', {
          method: 'Email'
        })
        setDone(true)

        if (prize) return setPrize(prize)
      }
    } catch (err) {
      const typedError = err as AxiosError
      const { code, message } = typedError.response?.data || {}
      dlTrackError({
        error_type: 'login',
        error_code: { code },
        error_message: { message },
        section: 'sign up'
      })
      setError(code)
    } finally {
      setLoad(false)
    }
  }

  const catchError = (error, setError) => {
    const typedError = error as AuthError
    if (typedError.code) {
      gtag('event', 'Click', {
        event_category: GTM_EVENT_CATEGORY,
        event_label: 'SINGUP - Error email existente',
        value: '10'
      })
      dlTrackError({
        error_type: 'login',
        error_code: typedError.code,
        error_message: prettyError(typedError.code),
        section: 'sign up'
      })
      setError(typedError.code)
    } else if ((error as AxiosError).isAxiosError) {
      const typedError = error as AxiosError
      const { code } = typedError.response?.data || {}
      dlTrackError({
        error_type: 'login',
        error_code: { code },
        error_message: prettyError(code),
        section: 'sign up'
      })
      return setError(code ?? 9999)
    }
  }

  const handleSubmit = async (data, setError) => {
    const {
      firstName,
      lastName,
      nickName,
      birthday,
      genreId,
      email,
      password,
      code,
      subscribe,
      termsOfUse,
      privacyPolicy
    } = data
    gtag('event', 'step_3', { event_label: 'Registro - Email', method: 'Email' })
    gtag('event', 'sign_up', { event_label: 'Registro - Email', method: 'Email' })
    setLoad(true)
    try {
      const credentials = await createUserWithEmailAndPassword(auth, email, password)
      const utm = utmServices.utmRead()
      const lang = textServices.language
      const data = {
        credentials,
        firstName,
        lastName,
        nickName,
        birthday,
        genreId,
        utm,
        code,
        subscribe,
        termsOfUse,
        lang,
        privacyPolicy
      }

      const response = await ProfileService.signUp(data)

      MetaCAPIService.emitCompleteRegistration(
        email,
        firstName,
        lastName,
        dayjs(birthday).format('YYYYMMDD'),
        window.location.href,
        'Email',
        MetaCAPIService.uuidv4()
      )

      setCredentials(credentials)
      localStorage.setItem('allowCookies', 'yes')
      setName(nickName || firstName)
      setEmail(email)
      setPassword(password)
      setPrizeToShow(response?.data?.prize)
      setOtp(true)
    } catch (error) {
      catchError(error, setError)
    } finally {
      setLoad(false)
    }
  }

  const handleSubmitBase = async (data) => {
    const { email, password, ...rest } = data
    setLoad(true)
    gtag('event', 'sign-up', { event_label: 'Registro - Email', method: 'Email' })
    try {
      const credentials = await createUserWithEmailAndPassword(auth, email, password)
      const utm = utmServices.utmRead()
      const data = { credentials, utm, ...rest }
      await ProfileService.signUp(data)
      setCredentials(credentials)
      localStorage.setItem('allowCookies', 'yes')
      setName(rest?.firstName)
      setEmail(email)
      setPassword(password)
      setOtp(true)
    } catch (error) {
      catchError(error, setError)
    } finally {
      setLoad(false)
    }
  }

  if (error) return <ErrorView code={error} buttonMenu={() => setError(null)} onRetry={() => setError(null)} />

  if (prize)
    return (
      <ExchangeComponent
        buttons={{
          handleMenu: () => handleUser(dataUser, credentials),
          handleButtonCommon: () => handleUser(dataUser, credentials),
          handleButtonSticker: () => handleUser(dataUser, credentials)
        }}
        item={prize}
        module="login"
        name={dataUser?.firstName}
      />
    )

  if (done) return <Done handleLogin={handleLogin} name={name} />

  if (otp) return <Welcome name={name} email={email} handleConfirm={handleConfirm} />
  return (
    <section className={`flex w-full ${!scanQr ? 'pt-7' : ''}`}>
      {CONFIGS?.signUp?.base ? (
        <FormBaseInformation handleSubmit={handleSubmitBase} />
      ) : (
        <FormInformation
          isSignUp={true}
          handleSubmit={handleSubmit}
          quantityStep={3}
          user={{ birthday } as any}
          handleScanQr={handleScanQr}
          scanQr={scanQr}
        />
      )}
    </section>
  )
}

export default SignUp
