import { useEffect } from 'react'
import textServices from 'services/text-services'

const BorderBlack = ({ isScanningSuccess }: { isScanningSuccess: boolean }) => {
  useEffect(() => {
    const footer = document.getElementsByClassName('footer')[0] as HTMLElement

    if (footer) {
      footer.style.display = 'none'

      return () => {
        footer.style.display = 'flex'
      }
    }
  }, [])

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        zIndex: 2
      }}
      className="sign-up__scan-qr"
    >
      {/* Div superior negro */}
      <div
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          height: 'calc(50% - 167.31px)'
        }}
        className="flex justify-center items-center "
      >
        <p className="text-white sign-up__scan-qr__title">
          {textServices.findByText('signup/referral/scanqr/title', {
            es: (
              <>
                Acerca tu cámara al QR que <br /> aparece en el otro celular para <br /> escanearlo.
              </>
            ),
            en: (
              <>
                Bring your camera close to the QR code <br /> displayed on the other device to <br /> scan it.
              </>
            )
          })}
        </p>
      </div>

      {/* Contenedor central que incluye los lados y la imagen */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          height: '334.63px'
        }}
      >
        {/* Div izquierdo negro */}
        <div
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            width: 'calc(50% - 163.5px)',
            height: '100%'
          }}
        ></div>

        {/* Imagen central */}
        <div className="relative">
          <img
            src={`${AZURE_BASE_URL}/signup-scan-qr.webp`}
            alt="Capture"
            style={{
              width: '327px',
              height: '334.63px',
              zIndex: 3
            }}
          />

          <div className="square">
            <div
              className="scan"
              style={{
                background: isScanningSuccess && 'linear-gradient(transparent, rgba(0, 255, 0, 0.8), transparent)'
              }}
            ></div>
          </div>
        </div>
        {/* Div derecho negro */}
        <div
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            width: 'calc(50% - 163.5px)',
            height: '100%'
          }}
        ></div>
      </div>

      {/* Div inferior negro */}
      <div
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          height: 'calc(50% - 167.31px)'
        }}
      ></div>
    </div>
  )
}

export default BorderBlack
