import Button from 'components/button'
import { useEffect, useState } from 'react'
import { useLocalStorage } from 'react-use'
import textServices from 'services/text-services'
import { Checkbox, DivCheckbox, PCheckbox, ReferralInput } from 'theme/theme'
import { ImgAllowCookies } from 'theme/allowcookies'
import useMainClass from 'hooks/use-main-cass'
import AlreadyParticipate from 'views/sign-up/components/already-participate'
import CONFIGS from 'build/Config'
import ScanQrReferral from './scanQr'

function AllowCookies({ onSubmit }) {
  const [referralCode, setReferralCode] = useState('')
  const [termsOfUse, setTermsOfUse] = useState(false)
  const [privacyPolicy, setPrivacyPolicy] = useState(false)
  const [subscribe, setSubscribe] = useState(false)
  useMainClass('start__allow-cookies')
  const [flowType] = useLocalStorage('flowType')
  const [isFlowType, setIsFlowType] = useState(false)
  const [scanQr, setScanQr] = useState(false)
  const [scanQrResult, setScanQrResult] = useState('')
  const [referral] = useLocalStorage<string>('referralCode', scanQrResult)

  const handleSubscribe = () => {
    gtag('event', 'Click', {
      event_category: GTM_EVENT_CATEGORY,
      event_label: 'MODIFICAR INFORMACION - Acepto recibir novedades',
      value: '10'
    })
    setSubscribe((state) => !state)
  }

  const handleTermsOfUse = () => {
    gtag('event', 'Click', {
      event_category: GTM_EVENT_CATEGORY,
      event_label: 'MODIFICAR INFORMACION - Acepto terminos y condiciones ',
      value: '10'
    })
    setTermsOfUse((state) => !state)
  }

  const handlePrivacyPolicy = () => {
    gtag('event', 'Click', {
      event_category: GTM_EVENT_CATEGORY,
      event_label: 'MODIFICAR INFORMACION - Acepto condiciones de privacidad',
      value: '10'
    })
    setPrivacyPolicy((state) => !state)
  }

  const handleOnChange = (e) => {
    const { value } = e.target
    setReferralCode(value)
  }

  const handleSubmit = () => {
    if (!termsOfUse) {
      return alert('Debes aceptar los términos y condiciones')
    }

    if (!privacyPolicy) {
      return alert('Debes aceptar el aviso de privacidad.')
    }

    if (flowType === 'alreadyParticipate') return setIsFlowType(true)

    onSubmit({ termsOfUse, subscribe: subscribe, privacyPolicy, code: referralCode })
    localStorage.setItem('allowCookies', 'yes')
  }

  const handleLogin = async () => {
    onSubmit({ termsOfUse, privacyPolicy, subscribe: subscribe, code: referralCode })
    localStorage.setItem('allowCookies', 'yes')
  }

  const getReferralCode = () => {
    const params = new URLSearchParams(window.location.search)
    const referralCodeParams = params.get('referralCode')
    console.log(params)
    console.log(referralCodeParams)
    if (referralCodeParams) {
      console.log('referralCodeParams', referralCodeParams)
      setReferralCode(referralCodeParams)
    }
  }

  useEffect(() => {
    getReferralCode()
  }, [])

  if (isFlowType) return <AlreadyParticipate handleLogin={handleLogin} />

  if (scanQr) return <ScanQrReferral toggle={() => setScanQr(!scanQr)} setScanQrResult={setScanQrResult} />

  return (
    <section className="flex-1 flex flex-col items-center large-screen-padding" style={{ maxHeight: 1080 }}>
      <div className="flex-1 flex flex-col items-center justify-center bg-cover md:bg-contain bg-no-repeat bg-center gap-3 w-full">
        <ImgAllowCookies src={`${AZURE_BASE_URL}/allowcookies.png`} alt="allowcookies" className="w-full" />
      </div>
      <div className="flex flex-col items-center justify-center gap-5 px-8 pb-8 w-full">
        {CONFIGS?.allowReferral && (
          <>
            <p className="dinPro font-medium text-base text-center referral-label">
              {textServices.findByText('allowcookies/subtitle', {
                en: 'ENTER YOUR REFERRAL CODE',
                es: 'INGRESA SU CÓDIGO DE REFERIDO'
              })}
            </p>
            <div className="w-full relative">
              <button className="absolute z-10  right-2 top-0 bottom-0" onClick={() => setScanQr(!scanQr)}>
                <img src={`${AZURE_BASE_URL}/referral_scan_qr_icon.svg`} alt="scan qr" />
              </button>
              <ReferralInput
                type="text"
                defaultValue={scanQrResult || (referral ?? undefined)}
                onChange={handleOnChange}
                placeholder="WFF7759"
                className="text-center saira font-bold text-sm referral-input"
              />
            </div>
          </>
        )}
        <div className="flex gap-3 flex-col w-full">
          <DivCheckbox className="gap-4 flex items-center cursor-pointer input-container" onClick={handleTermsOfUse}>
            <Checkbox active={termsOfUse} className={`h-6 w-6 flex items-center justify-center`} id="termsOfUse" />
            <PCheckbox className="dinPro input-checkbox-label flex-1">
              {textServices.findByText('signup/termsOfUse/label', {
                es: (
                  <>
                    Acepto los{' '}
                    <a
                      href={`${process.env.PUBLIC_URL}/terms-and-conditions`}
                      rel="noreferrer"
                      target="_blank"
                      className="underline"
                    >
                      Términos y Condiciones
                    </a>
                    .
                  </>
                ),
                en: (
                  <>
                    I accept the{' '}
                    <a
                      href={`${process.env.PUBLIC_URL}/terms-and-conditions`}
                      rel="noreferrer"
                      target="_blank"
                      className="underline"
                    >
                      Terms and Conditions
                    </a>
                    .
                  </>
                )
              })}
            </PCheckbox>
          </DivCheckbox>
          <DivCheckbox className="gap-4 flex items-center cursor-pointer input-container" onClick={handlePrivacyPolicy}>
            <Checkbox
              active={privacyPolicy}
              className={`h-6 w-6 flex items-center justify-center`}
              id="privacyPolicy"
            />
            <PCheckbox className="dinPro input-checkbox-label flex-1">
              {textServices.findByText('signup/privacyPolicy/label', {
                es: (
                  <>
                    Acepto el{' '}
                    <a
                      href={`${process.env.PUBLIC_URL}/privacy-politics`}
                      rel="noreferrer"
                      target="_blank"
                      className="underline"
                    >
                      Aviso de privacidad
                    </a>
                    .
                  </>
                ),
                en: (
                  <>
                    I accept the{' '}
                    <a
                      href={`${process.env.PUBLIC_URL}/privacy-politics`}
                      rel="noreferrer"
                      target="_blank"
                      className="underline"
                    >
                      Privacy Policy
                    </a>
                    .
                  </>
                )
              })}
            </PCheckbox>
          </DivCheckbox>
          <DivCheckbox className="gap-4 flex items-center cursor-pointer input-container" onClick={handleSubscribe}>
            <Checkbox className={`h-6 w-6 flex items-center justify-center`} id="suscribe" active={subscribe} />
            <PCheckbox className="dinPro input-checkbox-label">
              {textServices.findByText('allowcookies/checkbox/p/novedades', {
                es: 'Quiero recibir novedades.',
                en: 'I want to receive news.'
              })}
            </PCheckbox>
          </DivCheckbox>
        </div>
        <Button onClick={handleSubmit}>
          {textServices.findByText('allowcookies/checkbox/p/novedades', { es: 'SIGUIENTE', en: 'CONTINUE' })}
        </Button>
      </div>
    </section>
  )
}

export default AllowCookies
