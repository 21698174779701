import { GET_LINEUPS, GET_LINEUPS_PROFILE } from "constants/api"
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react"
import useOfflineData from "hooks/use-offline-data"
import useLocalStorage from "hooks/use-local-storage"
import { LoaderContext } from "contexts/loader-context"
import { useOnline } from "contexts/online-context"
import axios from "axios"
import { useSession } from "contexts/session-context"
import { ILineup, ILineupAllDTO } from "../types"
import 'dayjs/locale/es';

dayjs.locale('es');


const transform = (data: ILineupAllDTO): ILineup[] => {
  const dates: ILineup[] = []

  data?.eventLineUpItems?.forEach((artist) => {
    const currentDay = dayjs(artist?.beginAt).format('YYYY-MM-DD')
    const existIndex = dates?.findIndex((item) => item.day === currentDay) 

    const adaptedArtist = {
      id: artist?.id,
      validFrom: artist?.beginAt,
      validTo: artist?.endAt,
      imageSrc: artist?.imageUrl,
      name: artist?.name,
      stage: artist?.eventPlace,
      description: artist?.description
    }

    if (dates[existIndex]) {
      dates[existIndex] = {
        ...dates[existIndex],
        artists: [
          ...dates[existIndex].artists,
          adaptedArtist
        ]
      }
    } else {
      dates.push({
        id: currentDay,
        day: currentDay,
        artists: [
          adaptedArtist
        ]
      })
    }
  })

  return dates
}

export const useLineups = (eventId?: number) => {
  const { data } = useOfflineData<ILineupAllDTO>({ offline: 'lineup-create', url: `${GET_LINEUPS.replace(':id', eventId ? `${eventId}` : '0')}`})
  const [ transformedData, setTransformedData ] = useState<ILineup[]>([])

  useEffect(() => {
    if (data) {
      setTransformedData(transform(data))
    }
  },[data])


  return { data: transformedData, dataNotTransformed: data, eventId: data?.eventId, lineupId: data?.id }
}

export const useLineupsProfile = (eventId?: number) => {
  const [data, setData] = useState<number[] | null>(null)
  const [lineup, setLineup] = useLocalStorage<ILineup[]>('lineup')
  const isOnline = useOnline()
  const [{ data: session }] = useSession()
  const { setLoad } = useContext(LoaderContext)

  const fetcher = async () => {
    setLoad(true)
    try {
      const ids = []
      if (isOnline) {
        const { data: response } = await axios.get<ILineupAllDTO>(`${GET_LINEUPS_PROFILE.replace(':id', eventId ? `${eventId}` : `0`)}`, { headers: { Authorization: `Bearer ${(session as any)?._tokenResponse?.idToken}`, 'Ph-Api-Id': PH_API_ID ?? 1 } })
        setLineup(transform(response))
        response?.eventLineUpItems?.forEach((item) => ids.push(item?.id))
      } else {
        lineup?.forEach((item) => {
          item?.artists?.forEach((artist) => {
            ids.push(artist?.id)
          })
        })
      }

      if (ids.length > 0) {
        setData(ids)
      }
    } catch (err) {
      console.log(err)
    } finally {
      setLoad(false)
    }
  }

  useEffect(() => {
    fetcher()
  }, [])
  
  return data
}