import Rank from "components/icon/rank";
import TriviasIcon from "components/icon/trivias";
import Auctions from "components/icon/auctions";
import { Link } from "react-router-dom";
import { ButtonNavStyle, ButtonNavStyleFull } from "theme/home";
import { dlTrackEvent } from "components/dataLayer/dataLayer";
import useWhiteBg from "hooks/use-white-bg";
import Profile from "components/icon/profile";
import Raffles from "components/icon/raffles";
import CrowAguila from "components/icon/crow-aguila";
import Referrals from "components/icon/referrals";
import Collection from "components/icon/collection";
import Awards from "components/icon/awards";
import { TextHomeButton } from "views/home/style/text.style";
import Prizes from "components/icon/prizes";
import TriviasIconIndio from "components/icon/TriviasIndio";
import LineUpIndio from "components/icon/LineUpIndio";
import TecateID from "components/icon/tecate/tecateid";
import textServices from "services/text-services";
import ConsumptionHnk from "components/icon/consumptionHnk";
import HeinekenID from "components/icon/heinekenid";
import MixxID from "components/icon/mixx/mixx-id";
import DosequisID from "components/icon/dosequis/dosequis-id";
import RockID from "components/icon/indio/rock-id";
import UploadImageSilver from "components/icon/upload-image-silver";
import VoteSilverIcon from "components/icon/vote-silver";
import RankingSilverIcon from "components/icon/ranking-silver";
import { MezclasSolIcon, ProfileSolIcon, VoteSolIcon } from "components/icon/sol-icons";
import TriviasMillerIcon from "components/icon/trivias-miller";
import Redeem from 'components/icon/redeem'
import MemoramaSilverIcon from "components/icon/memorama-silver";
import CristalID from "components/icon/cristal/id";
import MemoramaNavTecate from "components/icon/tecate/memorama-nav";
import AmstelIdNav from "components/icon/amstel/amstel-id-nav";
import BohemiaIdNav from "components/icon/bohemia/bohemia-id-nav";
import HnkWaiterId from "components/icon/hnk-waiter/hnkWaiterID";
import SilverID from "components/icon/silver/silverId";
import MillerID from "components/icon/miller/miller-id-nav";
import SolMezclasId from "components/icon/solMezclas/solMezclasId";

const Links = {
  "Gift": {
    icon: CrowAguila,
    text: "INGRESAR CÓDIGOS",
    link: "/redeem"
  },
  "Rank": {
    icon: Rank,
    text: "RANKING",
    link: "/ranking"
  },
  "Auctions": {
    icon: Auctions,
    text: "SUBASTAS",
    link: "/auctions"
  },
  "Profile": {
    icon: Profile(),
    text: "MI PERFIL",
    link: "/profile/information"
  },
  "ProfileCB": {
    icon: Profile(),
    text: "MI PERFIL",
    link: "/profile"
  },
  "Raffles": {
    icon: Raffles,
    text: "PREMIO DIARIO",
    link: "/raffles"
  },
  "Referrals": {
    icon: Referrals,
    text: "REFERIR AMIGOS",
    link: "/referrals"
  },
  "Collections": {
    icon: Collection(),
    text: textServices.findByText('home/sellos', 'MIS COLECCIONES'),
    link: "/collections"
  },
  "Awards": {
    icon: Awards(),
    text: "MIS PREMIOS",
    link: "/prizes"
  },
  "Prizes": {
    icon: Prizes(),
    text: textServices.findByText('home/buttonNav/prizes', 'VITRINA DE PREMIOS'),
    link: "/prizes"
  },
  "Trivias": {
    icon: TriviasIcon,
    text: "TRIVIAS",
    link: "/trivias"
  },
  "TriviasIndio": {
    icon: TriviasIconIndio,
    text: "MIS TRIVIAS",
    link: "/trivias"
  },
  "Photos": {
    icon: `${AZURE_ICONS_URL}/${THEME}/photos.svg`,
    text: "SUS FOTOS",
    link: "/photographer/start?isYou=true"
  },
  "UploadImage": {
    icon: `${AZURE_ICONS_URL}/${THEME}/upload-image.svg`,
    text: "SUBIR FOTO",
    link: "/photographer/upload"
  },
  // TODO: Cambiar url por la externa de indio
  "LineUp": {
    icon: LineUpIndio,
    text: "LINE UP",
    href: true,
    link: `${URL_BASE}/rockid/lineup`
  },
  "ProfileID": {
    html: TecateID,
    type: 'component'
  },

  "MixxID": {
    html: MixxID,
    type: 'component'
  },
  "CristalID": {
    html: CristalID,
    type: 'component'
  },
  "ConsumptionHnk": {
    html: ConsumptionHnk,
    type: 'component'
  },
  "MemoramaSilver": {
    html: MemoramaSilverIcon,
    type: 'component'
  },
  "DosequisID": {
    html: DosequisID,
    type: 'component'
  },
  "RockID": {
    html: RockID,
    type: 'component'
  },
  "HeinekenId": {
    icon: HeinekenID,
    text: "",
    link: "/profile/identificacion",
    type: 'full'
  },
  "SolMezclasId": {
    icon: SolMezclasId,
    text: "",
    link: "/profile/identificacion",
    type: 'full'
  },
  "TriviaMatchpaint": {
    icon: `${AZURE_ICONS_URL}/${THEME}/paris.svg`,
    text: "VIAJE A PARÍS",
    link: "/trivias",
  },
  "UploadImageSilver": {
    icon: UploadImageSilver,
    text: "PARTICIPAR",
    link: "/album/redirect/Social/profile",
  },
  "VoteSilver": {
    icon: VoteSilverIcon,
    text: "VOTAR",
    link: "/album/start",
  },
  "RankingSilver": {
    icon: RankingSilverIcon,
    text: "RANKING",
    type: "full",
    link: "/album/redirect/Social/ranking",
  },
  "MezclasSol": {
    icon: MezclasSolIcon,
    text: "MIS MEZCLAS",
    link: "/home",
  },
  "VoteSol": {
    icon: VoteSolIcon,
    text: "VOTAR",
    link: "/home",
  },
  "ProfileSol": {
    icon: ProfileSolIcon,
    text: "MI PERFIL",
    link: "/profile",
  },
  "MyImagesSilver": {
    icon: UploadImageSilver,
    text: "MIS IMÁGENES",
    link: "/album/redirect/Foto/profile",
  },
  "ParticipateSilver": {
    icon: VoteSilverIcon,
    text: "PARTICIPAR",
    link: "/album/redirect/Foto/upload",
  },
  "TriviasMiller": {
    icon: TriviasMillerIcon,
    text: "MIS TRIVIAS",
    link: "/trivias"
  },
  "Redeem": {
    icon: Redeem(),
    text: textServices.findByText('home/buttonNav/redeem', 'INGRESAR CÓDIGOS'),
    link: "/redeem"
  },
  "HistoryDosequis": {
    icon: `${AZURE_ICONS_URL}/${THEME}/history.svg`,
    text: "HISTORIAS",
    link: "/album/redirect/Historia/upload"
  },
  "TicketsDosequis": {
    icon: `${AZURE_ICONS_URL}/${THEME}/receipts.svg`,
    text: "RECIBOS",
    link: "/album/redirect/Ticket/upload"
  },
  "ConsumptionDosequis": {
    icon: `${AZURE_ICONS_URL}/${THEME}/photo.svg`,
    text: "FOTOS",
    link: "/album/redirect/Consumo/upload"
  },
  "MemoramaTecate": {
    html: MemoramaNavTecate,
    type: 'component'
  },
  "SilverID": {
    html: SilverID,
    type: 'component'
  },
  "AmstelID": {
    html: AmstelIdNav,
    type: 'component'
  },
  "BohemiaID": {
    html: BohemiaIdNav,
    type: 'component'
  },
  "BohemiaChallenges": {
    icon: `${AZURE_ICONS_URL}/${THEME}/challenges-nav.svg`,
    text: 'RETOS',
    link: "/album/start"
  },
  "BohemiaContent": {
    icon: `${AZURE_ICONS_URL}/${THEME}/content-nav.svg`,
    text: 'MI CONTENIDO',
    link: "/album/redirect/Foto/profile"
  },
  "BohemiaRanking": {
    icon: `${AZURE_ICONS_URL}/${THEME}/ranking-nav.svg`,
    text: 'RANKING',
    link: "/album/redirect/Foto/ranking"
  },
  "MemoramaDosequis": {
    icon: `${AZURE_BASE_URL}/memorama-nav.svg`,
    text: 'MEMORAMA',
    link: "/memorama"
  },
  "PhotoDosequis": {
    icon: `${AZURE_BASE_URL}/photo-nav.svg`,
    text: 'SUBE TU FOTO',
    link: "/album/redirect/Consumo/upload"
  },
  "TicketsDosequisV2": {
    icon: `${AZURE_BASE_URL}/ticket-nav.svg`,
    text: 'SUBE TU TICKET',
    link: "/album/redirect/Ticket/upload"
  },
  "HnkWaiterID": {
    icon: HnkWaiterId,
    type: 'full',
    text: '',
    link: "/profile/identificacion"
  },
  "MillerID": {
    html: MillerID,
    type: 'component'
  },
}

function ButtonNav({ route }: any) {

  const { shouldUseWhiteBg } = useWhiteBg()
  const item = Links[route]

  const onHandleClick = (item: any) => {
    dlTrackEvent('user_interaction', {
      action: "click",
      element: item.text ?? item.category,
      section: "home"
    })
    gtag('event', 'event_to_ga', { event_category: item.category ?? item.text, event_label: 'Home links' })
  }

  if (item?.href) {
    return (
      <a className="w-1/3" href={item?.link} target="_blank" rel="noreferrer" onClick={() => { onHandleClick(item) }}>
        <ButtonNavStyle shouldUseWhiteBg={shouldUseWhiteBg} className="button-nav flex flex-col items-center justify-center gap-2 py-3 px-2 h-24 text-center font-medium text-xs">
          <span className="w-full flex-1 flex items-center justify-center">
            <img src={item.icon} alt={item.text} />
          </span>
          <TextHomeButton className="text-center font-medium text-xs saira leading-none">{item.text}</TextHomeButton>
        </ButtonNavStyle>
      </a>
    )
  }

  if (item.type === 'component') {
    return (
      <item.html />
    )
  }

  if (item.type === 'full') {
    return (
      <Link to={item.link} className="w-full" onClick={() => { onHandleClick(item) }}>
        <ButtonNavStyleFull shouldUseWhiteBg={shouldUseWhiteBg} className={`button-nav flex items-center justify-evenly py-3 px-2 h-24 text-center font-medium text-xs`}>
          {item.text && <TextHomeButton className="text-center font-medium text-xs saira leading-none">{item.text}</TextHomeButton>}
          {item.logo && <span className="w-full flex items-start justify-center"><item.logo /></span>}
          <span className=" flex items-center justify-center w-full">
            <item.icon />
          </span>
        </ButtonNavStyleFull>
      </Link>
    )
  }

  return (
    <Link to={item.link} className={'w-1/3'} onClick={() => { onHandleClick(item) }}>
      <ButtonNavStyle shouldUseWhiteBg={shouldUseWhiteBg} className={`button-nav flex flex-col items-center justify-center py-3 px-2 h-24 text-center font-medium text-xs`}>
        <span className="w-full flex-1 flex items-center justify-center">
          <img src={item.icon} alt={item.text} />
        </span>
        {item.text && <TextHomeButton className="text-center font-medium text-xs saira leading-none">{item.text}</TextHomeButton>}
        {item.logo && <span className=" flex items-center justify-center"><item.logo /></span>}
      </ButtonNavStyle>
    </Link>
  )
}

export default ButtonNav;