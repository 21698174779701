import React, { useRef, useEffect, useCallback, useState } from 'react'
import Webcam from 'react-webcam'
import ButtonCloseMenu from 'components/button-close'
import jsQR from 'jsqr'
import BorderBlack from './borderBlack'

const ScanQrReferral = ({
  toggle,
  setScanQrResult
}: {
  toggle?: () => void
  setScanQrResult: (arg0: string) => void
}) => {
  const webcamRef = useRef<Webcam>(null)
  const scanningRef = useRef(false)
  const [isScanningSuccess, setIsScanningSuccess] = useState(false)

  const extractReferralCode = (url: string): string | null => {
    const match = url.match(/referralCode=([A-Za-z0-9]{6})$/)
    return match ? match[1] : null
  }

  const scanQrFromCamera = useCallback(() => {
    const webcam = webcamRef.current

    if (!webcam || scanningRef.current) return

    scanningRef.current = true

    const imageSrc = webcam.getScreenshot()

    if (imageSrc) {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')

      const img = new Image()
      img.src = imageSrc

      img.onload = () => {
        canvas.width = img.width
        canvas.height = img.height
        context?.drawImage(img, 0, 0, img.width, img.height)

        const imageData = context?.getImageData(0, 0, img.width, img.height)

        if (imageData) {
          const qrCode = jsQR(imageData.data, imageData.width, imageData.height)

          if (qrCode?.data) {
            const referralCode = extractReferralCode(qrCode.data)

            if (referralCode) {
              setScanQrResult(referralCode)
              setIsScanningSuccess(true)
              setTimeout(() => setIsScanningSuccess(false), 1500)
              setTimeout(() => toggle?.(), 700)
            }
          }
        }
        scanningRef.current = false
      }
    } else {
      scanningRef.current = false
    }
  }, [setScanQrResult, toggle])

  useEffect(() => {
    const interval = setInterval(() => {
      scanQrFromCamera()
    }, 1000)

    return () => clearInterval(interval)
  }, [scanQrFromCamera])

  return (
    <>
      <ButtonCloseMenu paramFn={toggle} />
      <div className="w-full h-full relative">
        <div
          className={`absolute top-0 left-0 w-full h-full pointer-events-none ${
            isScanningSuccess ? 'scanning-success-animation' : ''
          }`}
        />
        <div className="w-full h-full flex items-center justify-center">
          <Webcam
            ref={webcamRef}
            audio={false}
            screenshotFormat="image/jpeg"
            videoConstraints={{ facingMode: 'environment' }}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
          />
          <BorderBlack isScanningSuccess={isScanningSuccess} />
        </div>
      </div>
    </>
  )
}

export default ScanQrReferral
