import ErrorView from "components/error-view"
import LoadingScreen from "components/loading-screen"
import { useEvents } from "contexts/events-context"
import { useSession } from "contexts/session-context"
import useMainClass from "hooks/use-main-cass"
import useRouter from "hooks/use-router"
import { lazy, Suspense } from "react"
import { Redirect, Route } from "react-router-dom"
import RoutesWithNotFound from "utilities/routes-with-not-found"

const Maps = lazy(() => import('./modules/maps/index'))
const PUBLIC_ROUTES = ['/map']

export default function EventsRouter() {
  useMainClass('events')
  const [{ event }, , loading] = useEvents()
  const [{ data: session }] = useSession()
  const { pathname, push } = useRouter()

  if (loading || !event) return <LoadingScreen />

  if (!PUBLIC_ROUTES?.some((route) => pathname.includes(route)) && !session) return <Redirect to={`/home`} />

  const getEventIdFromPath = () => {
    const match = pathname.match(/\/events\/(\d+)\/map/);
    return match ? { id: match[1] } : {};
  };

  const isActiveEvent = () => {
    const { id } = getEventIdFromPath();
    return id && id === event?.id.toString();
  };

  if (!isActiveEvent()) return (
    <ErrorView
      code={8100}
      onRetry={() => push('/home')}
      buttonMenu={() => push('/home')}
    />
  );

  return (
    <Suspense fallback={<LoadingScreen />}>
      <RoutesWithNotFound>
        <Route path="/events" component={() => <Redirect to={`/events/${event?.id}/map`} />} exact />
        <Route path="/events/:id/map" component={Maps} exact />
      </RoutesWithNotFound>
    </Suspense>
  )
}