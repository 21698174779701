import Button from 'components/button'
import ButtonCloseMenu from 'components/button-close'
import textServices from 'services/text-services'
import { useSession } from 'contexts/session-context'
import useMainClass from 'hooks/use-main-cass'
import { PrizesCouponType } from 'types/prizes.d'
import { ButtonSecondary, TextButton3 } from 'theme/congrats'
import { Link } from 'react-router-dom'
import {
  ExchangeContainerModal,
  ExchangeContainerText,
  ExchangeContainerTop,
  ExchangeDescription,
  ExchangeImage,
  ExchangePointsContainer,
  ExchangePointsText,
  ExchangeTitle
} from './styles'
import ScratchView from './scrachView'

interface IProps {
  item: {
    points?: number
    title: string
    imageUrl?: string
    stickerId?: number
    couponTypeId?: PrizesCouponType
  }
  title?: string | JSX.Element
  description?: string | JSX.Element
  buttons: {
    text?: string
    handleMenu: () => void
    dontShowButtons?: boolean
    handleButtonSticker?: () => void
    handleButtonCommon?: () => void
  }
  buttonSecondary?: {
    hidden: boolean
    text: string
    url: string
  }
  buttonTertiary?: {
    hidden: boolean
    text: string
    url: string
  }
  module?: string
  name?: string
  time?: number
  useRanking?: boolean
  className?: string
}

function formatTime(ms: number) {
  const totalSeconds = Math.floor(ms / 1000)
  const minutes = Math.floor((totalSeconds % 3600) / 60)
  const seconds = totalSeconds % 60
  const formattedMinutes = minutes.toString().padStart(2, '0')
  const formattedSeconds = seconds.toString().padStart(2, '0')

  return `${formattedMinutes}m ${formattedSeconds}s`
}

function ExchangeComponent({
  item,
  time,
  useRanking,
  title,
  description,
  buttons,
  module,
  name,
  buttonSecondary,
  buttonTertiary,
  className
}: IProps) {
  const [{ user }] = useSession()
  useMainClass(`${module}__exchange`)

  if (item?.couponTypeId === PrizesCouponType.SCRATCH)
    return <ScratchView item={item} buttons={buttons} module={module} />

  return (
    <ExchangeContainerModal className={`flex-1 space-y-4 flex flex-col p-8 justify-between w-full sm:max-w-sm ${className}`}>
      <ButtonCloseMenu open={true} paramFn={buttons?.handleMenu} />
      <ExchangeContainerTop className="flex-1 flex flex-col gap-6 items-center justify-center w-full">
        <ExchangeContainerText className="w-full">
          <ExchangeTitle className="uppercase text-bold text-3xl text-center title">
            {title || (
              <>
                ¡{textServices.findByText(`${module}/exchange/title`, { es: 'Veeeeenga', en: 'Come on' })}
                <br /> {user?.firstName ?? name}!
              </>
            )}
          </ExchangeTitle>
          {useRanking && (
            <div className="w-full flex flex-col gap-1 items-center text-center mb-3 exchange_time">
              <p className="text small">Tardaste:</p>
              <p className="border border-solid w-full px-3 py-3 flex items-center justify-center rounded-sm description medium time">
                <b>{formatTime(time)}</b>
              </p>
            </div>
          )}
          <ExchangeDescription className="text-center uppercase text-2xl din description">
            {description || (
              <>
                {textServices.findByText(
                  `${module}/exchange/description`,
                  { es: 'Ganaste', en: 'You won' },
                  { POINTS_COUNT: `${item?.points}` }
                )}{' '}
                <b>
                  {' '}
                  <br /> {item?.points <= 0 && item?.title}
                </b>
              </>
            )}
          </ExchangeDescription>
        </ExchangeContainerText>
        {item?.imageUrl ? (
          <ExchangeImage src={item?.imageUrl} alt={item?.title} />
        ) : item?.points ? (
          <ExchangePointsContainer
            className="w-48 h-48 flex justify-center items-center mx-auto text-center exchange_points_crown"
            style={{ backgroundSize: 'contain' }}
          >
            <ExchangePointsText className="text-4xl font-bold mx-auto">
              {item?.points}
              <br /> <span className="uppercase">{POINTS_NAME}</span>
            </ExchangePointsText>
          </ExchangePointsContainer>
        ) : (
          <></>
        )}
      </ExchangeContainerTop>
      {!buttons?.dontShowButtons && (
        <Button onClick={item?.stickerId > 0 ? buttons?.handleButtonSticker : buttons?.handleButtonCommon}>
          {buttons?.text ??
            textServices.findByText(`${module}/exchange/button/text`, {
              es: item?.stickerId > 0 ? 'VER MIS SELLOS' : 'VER MIS PREMIOS',
              en: item?.stickerId > 0 ? 'SEE MY SEALS' : 'SEE MY AWARDS'
            })}
        </Button>
      )}
      {(!buttonSecondary?.hidden && buttonSecondary?.url) && (
        <Link to={buttonSecondary?.url}>
          <ButtonSecondary>{buttonSecondary?.text}</ButtonSecondary>
        </Link>
      )}
      {(!buttonTertiary?.hidden && buttonTertiary?.url) &&
        <Link type="button" to={buttonTertiary?.url} className="cursor-pointer" >
          <TextButton3
            className={`din text-center uppercase underline pt-2`}
            style={{ fontSize: 10 }}
          >
            {buttonTertiary?.text}
          </TextButton3>
        </Link>
      }
    </ExchangeContainerModal>
  )
}

export default ExchangeComponent
