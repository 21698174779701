/* eslint-disable no-case-declarations */
export interface IEvent {
    "id": number,
    "name": string,
    "description": string,
    "statusId": number,
    "status": string,
    "beginAt": string,
    "endAt": string,
    "createdAt": string,
    "updatedAt": string,
    "imageUrl": string,
    "configurationId": number,
    "minZoom": number,
    "maxZoom": number,
    "center": {
        "latitude": number,
        "longitude": number
    },
    "southWest": {
        "latitude": number,
        "longitude": number
    },
    "northEast": {
        "latitude": number,
        "longitude": number
    },
}

export enum EventPlaceEnum {
  "Scenario" = 1,
  "Market" = 2,
  "Restrooms" = 3,
  "Store" = 4,
  "Exit" = 5,
  "Emergency exit" = 6,
  "Information" = 7,
  "Activity" = 8,
  "Parking" = 20,
  "Im" = 21,
}

export interface IEventPlaces {
  "id"?: number,
  "name"?: string,
  "description"?: string,
  "latitude"?: string,
  "longitude"?: string,
  "imageUrl": string,
  "status"?: boolean,
  "createdAt"?: string,
  "eventPlaceTypeId": number,
  "eventPlaceType": string,
  "width"?: number,
  "height"?: number
}

export type EventsActionType = 'SET_EVENT' | 'SET_EVENT_PLACES'

export interface EventsState {
  event?: IEvent
  places?: IEventPlaces[]
}

export interface EventsAction {
  type: EventsActionType
  payload: EventsState | IEvent | IEventPlaces[] | number
}

function eventsReducer(state: EventsState, action: EventsAction): EventsState {
  switch (action.type) {
  case 'SET_EVENT':
    return { ...state, event: action.payload as IEvent }
  case 'SET_EVENT_PLACES':
    return { ...state, places: action.payload as IEventPlaces[] }
  default:
    return state
  }
}

export default eventsReducer