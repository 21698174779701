import { IConfigModule } from 'build/types/configs'

const config: IConfigModule = {
  version: '2.0.0',
  allowReferral: true,
  filterPrizes: true,
  buttonsStart: ['signIn', 'signUp', 'facebook', 'google'],
  positiveLogoUrls: [
    '/terms-and-conditions',
    '/profile',
    '/help',
    '/trivias',
    '/congrats',
    '/ranking/suscribe',
    '/ranking/suscribe/confirm',
    '/end',
    '/trivia/:id',
    '/error/:errorid'
  ],
  useSecondBackground: ['/end', '/survey'],
  imageStart: 'backgroundImage',
  welcome: 'ImgBackground',
  legalAgeDate: true,
  routesLinks: [
    {
      path: '/album/redirect/Ticket/upload',
      text: 'SUBIR RECIBOS'
    },
    {
      path: '/redeem',
      text: 'CARGAR CÓDIGOS'
    },
    {
      path: '/prizes',
      text: 'VER LOS PREMIOS'
    },
    {
      path: '/profile',
      text: 'MI INFORMACIÓN'
    },
    {
      path: '/help',
      text: '¿Tienes alguna duda?',
      small: true
    },
    {
      path: '/terms-and-conditions',
      text: 'Ver términos y condiciones',
      small: true
    },
    {
      path: '/privacy-politics',
      text: 'Ver aviso de privacidad',
      small: true
    }
  ],
  routesHome: ['HnkWaiterID'],
  routesProfile: ['information', 'address', 'consumption', 'phone', 'movements', 'history', 'identificacion'],
  privacyPolitics: 'MX',
  cookies: 'MX',
  trivia: {
    showIntroduction: true,
    interestsRequired: false
  },
  notAvailableFields: ['instagram', 'nickName', 'sign'],
  notShowFields: ['instagram'],
  usePoints: false,
  profile: {
    notShowButtonInformation: true,
    showButtonPoints: true
  },
  album: {
    Ticket: {
      list: {
        urlRedirectAlbumComplete: '/album/start',
        urlRedirectAlbumProgress: '/album/:id/upload'
      },
      upload: {
        showHowToParticipate: true,
        validate: true,
        isTakeFoto: false,
        useLoaderValidate: true,
        useCongrats: true,
        useCongratsDirect: true,
        howTakePhoto: true
      }
    }
  },
  loaderPulse: true,
  captureBtnBgTransparent: true,
  prizes: {
    description: true
  },
  footer: true,
  popupClientNum: true,
  isWaiter: true
}

export default config
