import { GETCENTERLIST, GETCITYLIST, GETSTATELIST } from 'constants/api'
import axios, { AxiosError } from 'axios'
import Button from 'components/button'
import { LoaderContext } from 'contexts/loader-context'
import ClientNum from 'components/client-num/ClientNum'
import { useContext, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { SubtitleWaiterPage, TitleWaiterPage, Input, ErrorInput } from 'theme/theme'
import ErrorView from 'components/error-view'
import Select, { StylesConfig } from 'react-select'
import useMainClass from 'hooks/use-main-cass'
import { Link } from 'react-router-dom'
import { ClientContext } from 'contexts/client-num-context'
import CONFIGS from 'build/Config'

interface IProps {
  onFormSubmit: (data: any) => void
}

function FormConsumption({ onFormSubmit }: IProps) {
  useMainClass('consumptionform')

  const { handleSubmit, setValue } = useForm()
  const { setLoad } = useContext(LoaderContext)
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [centers, setCenters] = useState([])
  const [error, setError] = useState(null)
  const [clientNumber, setClientNumber] = useState('')
  const [errorForm, setErrorForm] = useState({ state: null, city: null, consumption: null })
  const { clientNum, setShowInputNumber, showInputNumber, setClientNum } = useContext(ClientContext)
  const cityRef = useRef<any>()
  const consumptionRef = useRef<any>()

  const fetcher = async (url: string, setState?: (data: any) => void) => {
    setLoad(true)
    try {
      const { data } = await axios.get(url)
      if (setState) setState(data)
      return data
    } catch (err) {
      const typedError = err as AxiosError
      const { code } = typedError.response?.data || {}
      setError(code)
    } finally {
      setLoad(false)
    }
  }

  useEffect(() => {
    fetcher(GETSTATELIST, setStates)
  }, [])

  useEffect(() => {
    if (!clientNum) {
      setShowInputNumber(true)
    } else {
      setShowInputNumber(false)
    }

    return () => {
      setClientNum(false)
    }
  }, [])

  const styles: StylesConfig = {
    option: (provided) => {
      return {
        ...provided,
        fontFamily: 'Frank',
        fontSize: '16px',
        fontWeight: 400
      }
    },
    container: (provided) => {
      return {
        ...provided,
        fontFamily: 'Frank',
        fontSize: '16px',
        fontWeight: 400,
        color: 'black',
        padding: '0.4rem',
        borderRadius: '50px',
        background: 'white',
        outline: 'none',
        '&:focus': {
          border: '1px solid #000'
        }
      }
    },
    singleValue: (provided) => {
      return {
        ...provided,
        color: '#000',
        fontFamily: 'Frank',
        fontSize: '16px',
        fontWeight: 400,
        textAlign: 'center'
      }
    },
    control: (provided) => {
      return {
        ...provided,
        color: '#000',
        background: 'none',
        border: 'none',
        outline: 'none',
        '&:focus': {
          border: 'none'
        },
        textAlign: 'center'
      }
    },
    input: (provided) => {
      return {
        ...provided,
        color: '#000',
        background: 'none',
        border: 'none',
        outline: 'none',
        '&:focus': {
          border: 'none'
        },
        textAlign: 'center'
      }
    },
    placeholder: (provided) => {
      return {
        ...provided,
        color: '#000',
        background: 'none',
        border: 'none',
        outline: 'none',
        '&:focus': {
          border: 'none'
        },
        textAlign: 'center'
      }
    }
  }

  const handleChange = (value, name: 'state' | 'consumption' | 'city', setState?: (value) => void, url?: string) => {
    let resetFields = false
    if (name === 'state') {
      resetFields = true
      cityRef.current.clearValue()
      setValue('city', null)
    }
    if (name === 'city') {
      resetFields = true
      setValue('consumption', null)
    }

    if (resetFields) {
      consumptionRef?.current?.clearValue()
      setClientNumber('')
    }

    if (!value?.value) return setErrorForm({ ...errorForm, [name]: 'Este campo es requerido' })
    setValue(name, value?.value)
    setErrorForm({ ...errorForm, [name]: null })
    if (url) return fetcher(`${url}/${value?.value}`, setState)
  }

  const onSubmit = (data) => {
    if (!data?.state || !data?.city || !data?.consumption) {
      return setErrorForm({
        state: !data?.state ? 'Este campo es requerido' : null,
        city: !data?.city ? 'Este campo es requerido' : null,
        consumption: !data?.consumption ? 'Este campo es requerido' : null
      })
    }
    onFormSubmit(data)
  }

  if (error) return <ErrorView code={error} buttonMenu={() => setError(null)} onRetry={() => setError(null)} />

  if (CONFIGS?.popupClientNum && showInputNumber) {
    return <ClientNum />
  }

  return (
    <div className="flex flex-col flex-1 md:pt-10">
      <form
        className="flex h-full justify-between flex-col gap-4 mx-7 mb-7"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <TitleWaiterPage className="text-3xl font-bold text-center pb-6">
          ¿Cuál es tu número de cliente?
        </TitleWaiterPage>
        <SubtitleWaiterPage className="text-center font-medium text-sm helvetica">
          Ingresa los datos de tu centro de consumo
        </SubtitleWaiterPage>
        <div className="flex-1 flex flex-col gap-4">
          <Select
            styles={styles}
            placeholder="Estado"
            options={states?.map((item) => ({ value: item?.id, label: item?.name }))}
            onChange={(value) => handleChange(value, 'state', setCities, GETCITYLIST)}
          />
          {errorForm?.state && <ErrorInput>{errorForm?.state}</ErrorInput>}
          <Select
            styles={styles}
            placeholder="Ciudad"
            ref={cityRef}
            options={cities?.map((item) => ({ value: item?.id, label: item?.name }))}
            onChange={(value) => handleChange(value, 'city', setCenters, GETCENTERLIST)}
          />
          {errorForm?.city && <ErrorInput>{errorForm?.city}</ErrorInput>}
          <Select
            styles={styles}
            placeholder="Centro de consumo"
            ref={consumptionRef}
            options={centers?.map((item) => ({ value: item?.id, label: item?.name, clientId: item?.internalId }))}
            onChange={(value) => {
              setClientNumber((value as any)?.clientId)
              handleChange(value, 'consumption')
            }}
          />
          {errorForm?.consumption && <ErrorInput>{errorForm?.consumption}</ErrorInput>}
          {CONFIGS?.popupClientNum && (
            <>
              <h2 className="consumptionform__clientnum">ESTE ES EL NUMERO DE CLIENTE</h2>
              <Input
                className="consumptionform__input"
                value={clientNumber}
                placeholder="(9 DÍGITOS)"
                type="tel"
                readOnly
                disabled
              />
            </>
          )}
        </div>

        <div>
          <p className="consumptionform__help">
            <b>¿NO PUEDES AVANZAR?</b> <br />
            Ponte en contacto con nosotros para que podamos
          </p>
          <p className="consumptionform__help mb-4">
            ayudarte.{' '}
            <Link to="/help" className="consumptionform__link">
              Envíanos un mensaje.
            </Link>
          </p>
        </div>
        <Button>
          <p className="uppercase">CONFIRMAR</p>
        </Button>
      </form>
    </div>
  )
}

export default FormConsumption
