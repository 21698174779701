import Button from 'components/button'
import ButtonCloseMenu from 'components/button-close'
import { dlTrackEvent } from 'components/dataLayer/dataLayer'
import useMainClass from 'hooks/use-main-cass'
import textServices from 'services/text-services'
import { Data } from '../referrals.interface'

const Modal = ({ closeModal, toggleRules, data }: { closeModal: () => void; toggleRules: () => void; data: Data }) => {
  useMainClass('referrals__modal')
  const closeModalFn = async () => {
    try {

      dlTrackEvent('user_interaction', {
        action: 'click',
        element: 'close modal',
        section: 'referrals'
      })

      closeModal()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <article className="w-full flex flex-col items-center pt-4 gap-6 pb-8 px-4">
      <ButtonCloseMenu paramFn={closeModalFn} />

      <h1 className="referrals__modal__title">
        {textServices.findByText('referrals/modal/title', {
          es: (
            <>
              ¿Cómo participar? <br /> <span>Refiere y gana</span>
            </>
          ),
          en: (
            <>
              How to participate? <br /> <span>Refer and earn</span>
            </>
          )
        })}
      </h1>

      <img
        src={data?.team?.prizeImageUrl ?? `${AZURE_BASE_URL}/referrals_modal_cover.webp`}
        alt="Referrals cover"
        loading="lazy"
      />

      <div className="flex flex-col gap-6 referrals__modal__content">
        {textServices.findByText('referrals/modal/content', <></>)}
      </div>

      <button className="referrals__modal__show-rules" onClick={toggleRules}>
        {textServices.findByText('referrals/modal/btn', { es: 'VER REGLAS', en: 'SEE RULES' })}
      </button>

      <Button onClick={closeModalFn} className="referrals__modal__btn-main">
        {textServices.findByText('referrals/modal/btn-main', {
          es: 'Referir mi código',
          en: 'Refer my code'
        })}
      </Button>
    </article>
  )
}

export default Modal
