import { useEffect, useState } from 'react'
import { dlTrackEvent } from 'components/dataLayer/dataLayer'
import useMainClass from 'hooks/use-main-cass'
import { LoadingDiv } from 'theme/loading'
import Rules from './components/Rules'
import Modal from './components/Modal'
import Header from './components/Header'
import ProgressBar from './components/ProgressBar'
import Code from './components/Code'
import { useReferral } from './hook/useReferral'

const Referrals = () => {
  useMainClass('referrals')
  const [openRules, setOpenRules] = useState(false)
  const [{ dynamic, team }, { getReferredTeam }] = useReferral()
  const [modal, setModal] = useState<any>(true)

  useEffect(() => {
    if(dynamic)
      getReferredTeam()
  }, [dynamic])

  const toggleRules = () => {
    dlTrackEvent('user_interaction', {
      action: 'click',
      element: !openRules ? 'see rules' : 'close rules',
      section: 'referrals'
    })

    setOpenRules(!openRules)
  }

  if (!dynamic && !team) {
    return <LoadingDiv />
  }

  if (openRules) {
    return <Rules closeRules={toggleRules} />
  }

  if (modal) {
    return <Modal data={team} closeModal={() => setModal(false)} toggleRules={toggleRules} />
  }

  return (
    <section className="w-full">
      <Header />
      <ProgressBar data={team} />
      <Code data={team} />
    </section>
  )
}

export default Referrals
